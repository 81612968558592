import {
  postRequest,
  uploadFile,
  getRequest,
  getRequestUrl,
  uploadFileCustom,
  deleteRequest,
  importPostRequest
} from "@/libs/axios";


// 上传文件限制参数
export const getUploadLimit = params => {
  return postRequest("/yethan/file/getUploadLimit", params, false);
};

// 上传文件校验
export const checkFile = (_this, size, ext, file) => {
  if (size < file.size) {
    _this.$notify.warning({
      message: "只能上传" + size / 1024 / 1024 + "M以内文件"
    });
    return false;
  }
  var fileExt = file.name.split(".")[file.name.split(".").length - 1];
  fileExt = fileExt.toLowerCase();
  if (ext.indexOf("." + fileExt) == -1) {
    _this.$notify.warning({
      message: "上传文件类型错误"
    });
    return false;
  }
  return true;
};

// 上传文件(单文件上传)
export const uploadFiles = (module, file) => {
  return uploadFile("/yethan/file/uploadOne?module=" + module, file);
};



//导出查询字段
export const exportItem = (templateCode) => {
  return getRequest("/yethan/data/template/listAll", {
    templateCode: templateCode
  });
};


// 确定导出
export const sureExport = (type, filename, params) => {
  return getRequestUrl(`/yethan/register/download/${type}/${filename}`, params);
};

// 自定义导入字段
export const importFileCustom = (files, params) => {
  return uploadFileCustom("/yethan/file/uploadData", files, params);
};

// 自定义导入确认
export const importFileSure = (fileId, module, params) => {
  return importPostRequest(`/yethan/register/upload/dbf/${fileId}/${module}`, params);
};
// 存起自定义匹配的字段
export const saveCustomFileds = (drkid, module, params) => {
  return postRequest(`/yethan/data/importMatch/saveMatch/${module}/${drkid}`, params);
};
// 获取已有的自定义匹配字段
export const getCustomFileds = (drkid, module) => {
  return getRequest(`/yethan/data/importMatch/matchlist?templateCode=${module}&drkid=${drkid}`);
};

// 查询最近导入文件
export const getLatelyFile = (drkid, module) => {
  return getRequest(`/yethan/data/importRecord/getFileId?templateCode=${module}&drkid=${drkid}`);
};
// 返回历史上传文件解析数据
export const analysisLatelyFile = (params) => {
  return postRequest(`/yethan/file/getHistoryImport`, params);
};

// 撤销导入
export const deleteUpload = (rollBackKey) => {
  return deleteRequest("/yethan/register/uploadRollBack", {
    rollBackKey: rollBackKey
  });
};

// 预制编码
export const getBm = (module, params) => {
  return getRequest(`/yethan/register/common/getBm/${module}`, params);
};

// 下载错误数据excel版
export const downloadError = (templateCode) => {
  return getRequest("/yethan/register/downloadBadList", {
    templateCode: templateCode
  });
};

// 下载错误数据dbf版
export const downloadDbfError = (params) => {
  return getRequest("/yethan/register/downCheckList", params);
};

// 临时表导入正式表
export const exportSubmit = (params) => {
  return postRequest("/yethan/register/uploadSubmit", params);
};

// 系统参数
export const getSysConfig = (key) => {
  return getRequest(`/yethan/sysConfig/getSysConfig/${key}`, {}, false);
};


export const getFileType = (fileId) => {
  return getRequest(`/yethan/file/type/${fileId}`);
};

// 另一个文件上传接口

export const fileUpload = (module, file) => {
  return uploadFile("/yethan/file/upload?module=" + module, file);
};
// 另一个获取文件列表
export function cjFileList(params) {
  return getRequest("/yethan/file/list", params);
}

// 报名库表格字段
export const getBmkFields = (ksbmbh) => {
  return getRequest(`/yethan/registerUser/bmk/fields/${ksbmbh}`);
};

// 报名库字段导出
export const exportBmkFields = (ksbmbh) => {
  return getRequest(`/yethan/register/field/${ksbmbh}/query`,{}, false);
};

// 查询报名统计
export const getBmstatisc = (params) => {
  return getRequest("/yethan/register/statistical/getStatistical", params);
};

// 刷新下载报名数据统计
export const refreshBmstatisc = (params) => {
  return getRequest("/yethan/register/statistical/deleteStatistical", params);
};


// 图片转换base64
export const changeUrl = (parmas) => {
  return postRequest(`/yethan/file/base64/record`, parmas);
};



// 表格显示的字段存起来
export const saveFileds = function(ksbmbh, querykeys) {
  return postRequest(`/yethan/registerUser/setquerykeys?ksbmbh=${ksbmbh}&querykeys=${querykeys}`)
}

/*
 ***管理员
 */
export default [{
    path: '/admin',
    name: 'adminIndex',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import( /* webpackPrefetch: true */ '../views/pages/admin/admin-index')
  },
  // 更多系统消息
  {
    path: '/admin/list',
    name: 'list',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/common/list')
  },
  // 账户
  {
    path: '/admin/userAccount',
    name: 'adminAccount',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/user-account')
  },
  // 详情
  {
    path: '/admin/details',
    name: 'details',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/common/details')
  },
  // 报名基础数据--考试大类管理
  {
    path: '/admin/baseBigType',
    name: 'baseBigType',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-bigType')
  },
  // 报名基础数据--考试二级分类
  {
    path: '/admin/baseSecondType',
    name: 'baseSecondType',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-secondType')
  },
  // 报名基础数据--选科专业
  {
    path: '/admin/baseMajors',
    name: 'baseMajors',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-majors')
  },
  // 报名基础数据--机构
  {
    path: '/admin/baseOrganization',
    name: 'baseOrganization',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-organization')
  },
  // 报名基础数据--单位
  {
    path: '/admin/baseCompany',
    name: 'baseCompany',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-company')
  },
  // 报名基础数据--部门
  {
    path: '/admin/baseDepartment',
    name: 'baseDepartment',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-department')
  },
  // 报名基础数据--职位
  {
    path: '/admin/basePosition',
    name: 'basePosition',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-position')
  },
  // 报名基础数据--考试科目
  {
    path: '/admin/baseSubject',
    name: 'baseSubject',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-subject')
  },
  // 报名基础数据--模板
  {
    path: '/admin/baseMould',
    name: 'baseMould',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-mould')
  },
  // 报名基础数据--字典数据
  {
    path: '/admin/baseData',
    name: 'baseData',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-data')
  },
  // 报名基础数据--免试库
  {
    path: '/admin/examFree',
    name: 'examFree',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-examFree')
  },
  // 报名基础数据--考全科
  {
    path: '/admin/general',
    name: 'general',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-general')
  },
  //字典详情
  {
    path: '/admin/baseDataDetail',
    name: 'baseDataDetail',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/base/base-dataDetail')
  },
  // 报名数据统计
  {
    path: '/admin/enrollStatic',
    name: 'enrollStatic',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-statistic')
  },
  // 考生报名审核
  {
    path: '/admin/enrollCheck',
    name: 'enrollCheck',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-check')
  },
  // 考生报名表打印
  {
    path: '/admin/enrollCheckPrint',
    name: 'enrollCheckPrint',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-check-print')
  },
  {
    path: '/admin/enrollCheckPrintJump',
    name: 'enrollCheckPrintJump',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-check-print-jump')
  },

  // 考生档案库
  {
    path: '/admin/enrollArchives',
    name: 'enrollArchives',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-archives')
  },
  // 考生违纪库
  {
    path: '/admin/enrollBreach',
    name: 'enrollBreach',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-breach')
  },
  // 考生黑名单库
  {
    path: '/admin/enrollHmdk',
    name: 'enrollHmdk',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-hmdk')
  },
  // 考生职位库
  {
    path: '/admin/enrollZwk',
    name: 'enrollZwk',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-zwk')
  },
  {
    path: '/admin/reportView',
    name: 'reportView',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/report-view')
  },
  {
    path: '/admin/reportView/professional',
    name: 'reportViewProfessional',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/report-professional-view.vue')
  },
  // 考生特殊名单库
  {
    path: '/admin/enrollTsmk',
    name: 'enrollTsmk',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-tsmdk')
  },
  // 考生专有名单库
  {
    path: '/admin/enrollZymdk',
    name: 'enrollZymdk',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-zymdk')
  },
  // 管理员--报名列表
  {
    path: '/admin/enrollList',
    name: 'enrollList',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/enroll/enroll-list')
  },
  // 管理员--成绩管理首页
  {
    path: '/admin/scoreIndex',
    name: 'scoreIndex',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-index')
  },
  // 管理员--成绩库列表
  {
    path: '/admin/scoreList',
    name: 'scoreList',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-list')
  },
  // 管理员--成绩库管理
  {
    path: '/admin/scoreManage',
    name: 'scoreManage',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-manage')
  },
  // 成绩详情
  {
    path: '/admin/scoreDetails',
    name: 'scoreDetails',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-details')
  },
  // 成绩库导入--基本信息
  {
    path: '/admin/scoreImport',
    name: 'scoreImport',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-import')
  },

  // 成绩库导入--匹配
  {
    path: '/admin/scoreImportMatch',
    name: 'scoreImportMatch',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-import-match')
  },
  // 成绩库导入--时间
  {
    path: '/admin/scoreImportTime',
    name: 'scoreImportTime',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-import-time')
  },
  // 成绩库导入--样式
  {
    path: '/admin/scoreImportStyle',
    name: 'scoreImportStyle',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-import-style')
  },
  // 成绩库导入--成绩单样式预览
  {
    path: '/admin/scoreStylePreview',
    name: 'scoreStylePreview',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-import-style-preview')
  },
  // 成绩--检查
  {
    path: '/admin/scoreImportCheck',
    name: 'scoreImportCheck',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-import-check')
  },
  // 成绩--复核
  {
    path: '/admin/scoreReview',
    name: 'scoreReview',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/score/score-review')
  },

  // 准考证-样式
  {
    path: '/admin/printCardStyle',
    name: 'printCardStyle',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/printCard/printCard-style')
  },
  // 管理员--准考证库列表
  {
    path: '/admin/printCardList',
    name: 'printCardList',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/printCard/printCard-list')
  },
  // 管理员--准考证库管理
  {
    path: '/admin/printCardManage',
    name: 'printCardManage',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/printCard/printCard-manage')
  },
  // 准考证导入--基本信息
  {
    path: '/admin/printCardImport',
    name: 'printCardImport',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/printCard/printCard-import')
  },

  // 准考证导入--匹配
  {
    path: '/admin/printCardImportMatch',
    name: 'printCardImportMatch',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/printCard/printCard-import-match')
  },
  // 准考证导入--时间
  {
    path: '/admin/printCardImportTime',
    name: 'printCardImportTime',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/printCard/printCard-import-time')
  },
  // 准考证导入--检查
  {
    path: '/admin/printCardImportCheck',
    name: 'printCardImportCheck',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/printCard/printCard-import-check')
  },
  // 准考证-打印预览
  {
    path: '/admin/printCardPreview',
    name: 'printCardPreview',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/printCard/printCard-style-preview')
  },
  // 证书--邮寄
  {
    path: '/admin/certificateSend',
    name: 'certificateSend',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/certificate/certificate-send')
  },
  // 证书--信息
  {
    path: '/admin/certificateInfo',
    name: 'certificateInfo',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/certificate/certificate-info')
  },
  // 证书库
  {
    path: '/admin/certificateManage',
    name: 'certificateManage',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/certificate/certificate-manage')
  },
  // 物流公司
  {
    path: '/admin/certLogisticsManage',
    name: 'certLogisticsManage',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/certificate/certificate-logistics-manage')
  },
  // 证书--费用
  {
    path: '/admin/certificateCost',
    name: 'certificateCost',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/certificate/certificate-cost')
  },
  //证书库--基本信息
  {
    path: '/admin/certImport',
    name: 'certImport',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/certificate/cert-import')
  },
  //证书库--导入匹配
  {
    path: '/admin/certImportMatch',
    name: 'certImportMatch',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/certificate/cert-import-match')
  },
  //证书库--设置考区+物流公司+收费标准
  {
    path: '/admin/cerImportLogistics',
    name: 'cerImportTime',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/certificate/cert-import-logistics')
  },
  //证书库--检查数据
  {
    path: '/admin/certImportCheck',
    name: 'certImportCheck',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/certificate/cert-import-check')
  },
  // 考试详情-
  {
    path: '/admin/examIndex',
    name: 'examIndex',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/exam/index')
  },
  // 考试报名-基本信息
  {
    path: '/admin/examEnroll',
    name: 'examEnroll',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/exam/exam-enroll')
  },
  // 考试报名-模板
  {
    path: '/admin/examEnrollMould',
    name: 'examEnrollMould',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/exam/exam-enroll-mould')
  },
  // 模板预览
  {
    path: '/admin/examMouldPreview',
    name: 'examMouldPreview',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/exam/exam-enroll-mould-preview')
  },

  // 考试报名-审核流程
  {
    path: '/admin/examEnrollProgress',
    name: 'examEnrollProgress',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/exam/exam-enroll-progress')
  },
  // 考试报名-考试时间
  {
    path: '/admin/examTime',
    name: 'examTime',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/exam/exam-time')
  },
  // 考试报名-关联库
  {
    path: '/admin/examBaseSet',
    name: 'examBaseSet',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/exam/exam-base-set')
  },
  // 考试报名-收费
  {
    path: '/admin/examFees',
    name: 'examFees',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/exam/exam-fees')
  },
  // 系统设置-参数配置
  {
    path: '/admin/systemParams',
    name: 'systemParams',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/system-params')
  },

  // 系统设置-备份数据库
  {
    path: '/admin/systemBackup',
    name: 'systemBackup',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/system-backup')
  },
  // 系统设置-备份数据表
  {
    path: '/admin/systemBackupTable',
    name: 'systemBackupTable',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/system-backup-table')
  },
  // 系统设置-短信
  {
    path: '/admin/systemMessage',
    name: 'systemMessage',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/system-message')
  },
  // 系统设置-邮件
  {
    path: '/admin/systemEmail',
    name: 'systemEmail',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/system-email')
  },
  // 系统设置-日志-登录日志
  {
    path: '/admin/systemLog/userLogin',
    name: 'systemLog-userLogin',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/system-log-userLogin')
  },
  // 系统设置-日志-操作日志
  {
    path: '/admin/systemLog/userOperation',
    name: 'systemLog-userOperation',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/system-log-userOperation')
  },
  // 系统设置-日志-报名审核日志
  {
    path: '/admin/systemLog/enrollCheck',
    name: 'systemLog-enrollCheck',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/system-log-enrollCheck')
  },
  // 系统设置-日志-成绩管理日志
  {
    path: '/admin/systemLog/scoreSet',
    name: 'systemLog-scoreSet',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/system-log-scoreSet')
  },
  // 系统监控-在线用户
  {
    path: '/admin/onlineUser',
    name: 'onlineUser',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/online-user')
  },
  // 系统监控-服务管理
  {
    path: '/admin/server',
    name: 'adminServer',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/server')
  },
  // 系统监控-缓存监控
  {
    path: '/admin/cache',
    name: 'adminCache',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/system/cache')
  },
  // 支付管理
  {
    path: '/admin/payManage',
    name: 'payManage',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/payment/pay-manage')
  },
  // 支付管理
  {
    path: '/admin/payRecord',
    name: 'payRecord',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/payment/pay-record')
  },
  // 缴费管理
  {
    path: '/admin/chargeManage',
    name: 'chargeManage',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/payment/charge-manage')
  },
  // 收费统计
  {
    path: '/admin/chargeStatistics',
    name: 'chargeStatistics',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/payment/charge-statistics')
  },
  // 退费查询
  {
    path: '/admin/refund',
    name: 'refund',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/payment/refund')
  },

  // 商户管理
  {
    path: '/admin/merchant',
    name: 'merchant',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/payment/pay-merchant')
  },
  //用户管理--考生
  {
    path: '/admin/examinee',
    name: 'examinee',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/examinee/examinee')
  },

  //用户管理--考生基本信息
  {
    path: '/admin/personalData',
    name: 'personalData',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/examinee/personal-data')
  },
  //用户管理--考生照片信息
  {
    path: '/admin/personalImage',
    name: 'personalImage',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/examinee/personal-image')
  },
  //用户管理--考生学历信息
  {
    path: '/admin/personalEducation',
    name: 'personalEducation',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/examinee/personal-education')
  },
  //用户管理--考生工作经历
  {
    path: '/admin/personalWork',
    name: 'personalWork',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/examinee/personal-work')
  },
  //用户管理--机构用户--原先的暂时保留
  {
    path: '/admin/institution',
    name: 'institution',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/institution')
  },
  //用户管理--机构用户审核--原先的暂时保留
  {
    path: '/admin/institutionCheck',
    name: 'institutionCheck',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/institution/institution-check')
  },
  //用户管理--机构用户
  {
    path: '/admin/institutionManage',
    name: 'institutionManage',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/institution/institution-manage')
  },
  //用户管理--机构用户
  {
    path: '/admin/institutionVerify',
    name: 'institutionVerify',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/institution/institution-verify')
  },
  //用户管理--机构用户资料
  {
    path: '/admin/institutionData',
    name: 'institutionData',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/institution/institution-data')
  },
  //用户管理--管理员用户
  {
    path: '/admin/userAdmin',
    name: 'userAdmin',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/user-admin')
  },
  //用户管理--管理员用户--部门设置
  {
    path: '/admin/department',
    name: 'department',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/user-admin/department')
  },
  //工单管理--列表
  {
    path: '/admin/workList',
    name: 'WorkList',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/workorder/workorder-list')
  },
  {
    path: '/admin/workList/:id',
    name: 'workList',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/workorder/workorder-list')
  },
  //工单管理--类别
  {
    path: '/admin/workorderType',
    name: 'workorderType',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/workorder/workorder-type')
  },
  //网站管理--新闻公告发布
  {
    path: '/admin/announcePublish',
    name: 'announcePublish',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/announce-publish')
  },
  //网站管理--新闻公告列表
  {
    path: '/admin/announcement',
    name: 'announcement',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/announcement')
  },
  //网站管理--热搜列表
  {
    path: '/admin/hotmanagement',
    name: 'hotmanagement',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/hotmanagement')
  },
  //网站管理--文件上传
  {
    path: '/admin/file',
    name: 'file',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/file')
  },
  //网站管理--规章制度
  {
    path: '/admin/rules',
    name: 'rules',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/rules')
  },
  //网站管理--banner
  {
    path: '/admin/banner',
    name: 'banner',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/banner')
  },
  //网站管理--公共信息
  {
    path: '/admin/common',
    name: 'common',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/common')
  },
  //网站管理--公共信息编辑
  {
    path: '/admin/commonPublish',
    name: 'commonPublish',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/common-publish')
  },
  //网站管理-推送信息
  {
    path: '/admin/tips',
    name: 'tips',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/tips')
  },
  //网站管理-推送信息
  {
    path: '/admin/tipsPublish',
    name: 'tipsPublish',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/tips-publish')
  },
  {
    path: '/admin/tipsScan',
    name: 'tipsScan',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/web-manage/tips-scan')
  },
  {
    path: '/admin/build',
    component: () => import('../views/tool/build/index')
  },

  //网站管理--公共信息编辑
  {
    path: '/admin/drag',
    name: 'drag',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import("../components/demo/drog-test"),
  },
  {
    path: '/demo/personal',
    name: 'demoPersonal',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import("../components/demo/person"),
  },
  {
    path: '/demo/personal1',
    name: 'demoPersonal1',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import("../components/user/person-show.vue"),
  },
  {
    path: '/admin/userAccountDeal',
    name: 'userAccountDeal',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import("@/views/pages/admin/user/user-account-deal"),
  },
  {
    path: '/admin/institutionTable',
    name: 'institutionTable',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import("@/views/pages/admin/user/institution/institution-table.vue"),
  },
  {
    path: '/admin/examinationAuthority',
    name: 'examinationAuthority',
    component: () => import("@/views/pages/admin/examination/modeDepartment/examination-authority.vue"),
  },
  {
    path: '/admin/examinationDepartment',
    name: 'examinationDepartment',
    component: () => import("@/views/pages/admin/examination/modeDepartment/examination-department.vue"),
  },
  {
    path: '/admin/examinationDepartmentByLocal',
    name: 'examinationDepartmentByLocal1',
    component: () => import("@/views/pages/admin/examination/modeLocal/examination-department.vue"),
  },
  {
    path: '/admin/examinationDepartmentByLocal',
    name: 'examinationDepartmentByLocal',
    component: () => import("@/views/pages/admin/examination/modeLocal/examination-department.vue"),
  }, {
    path: '/admin/examinationAuthorityByRange',
    name: 'examinationAuthorityByRange',
    component: () => import("@/views/pages/admin/examination/modeRange/examination-authority.vue"),
  }, {
    path: '/admin/img/handle',
    name: 'imgHandle',
    component: () => import("@/views/pages/admin/user/userinfo/img.vue"),
  }, {
    path: '/admin/edu/handle',
    name: 'imgHandleEdu',
    component: () => import("@/views/pages/admin/user/userinfo/edu.vue"),
  }, {
    path: '/admin/checkNewWin',
    name: 'checkNewWin',
    component: () => import("@/views/pages/admin/enroll/check-new-win.vue"),
  }, {
    path: '/admin/workOrderBigType',
    name: 'workOrderBigType',
    component: () => import("@/views/pages/admin/workorder/workorder-type.vue"),
  }, {
    path: '/admin/workOrderSmallType',
    name: 'workOrderSmallType',
    component: () => import("@/views/pages/admin/workorder/workorder-small-type.vue"),
  }, {
    path: '/admin/workOrderHandle',
    name: 'adminWorkOrderHandle',
    component: () => import("@/views/pages/admin/workorder/order/workOrderHandle.vue"),
  }, {
    path: '/admin/workOrderView',
    name: 'adminWorkOrderView',
    component: () => import("@/views/pages/admin/workorder/order/workOrderView.vue"),
  }, {
    path: '/admin/creditReviewList',
    name: 'creditReviewList',
    component: () => import("@/views/pages/admin/credit-review/list.vue"),
  }, {
    path: '/admin/dynamicForm',
    name: 'dynamicForm',
    component: () => import("@/components/demo/dynamic-form.vue"),
  }, {
    path: '/admin/userAdminHide',
    name: 'userAdminHide',
    component: () => import("@/views/pages/admin/user/user-admin/index-hide"),
  },

  {
    path: '/admin/informationCollaboration',
    name: 'informationCollaboration',
    component: () => import('../views/pages/admin/information/information-collaboration')
  },
  {
    path: '/admin/informationLog',
    name: 'informationLog',
    component: () => import('../views/pages/admin/information/information-log')
  },
];
/*
 ***管理员结束
 */

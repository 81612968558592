<template>
  <div v-if="workList">
    <div class="mt-2 mb-2">
      <i class="iconfont icon-ze-user-circle-o mr-2"></i>工作经历
    </div>
    <div class="msgContent flexList fs-xs">
      <table class="table light-table table-hover" v-if="workList.length != 0">
        <thead class="thead-light">
          <tr>
            <th>序号</th>
            <th>起止时间</th>
            <th>国家地区</th>
            <th>单位名称</th>
            <th>职务</th>
            <th>职称</th>
            <th>主要工作介绍</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in workList" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ item.gzqsrq }}至{{ item.gzzzrq }}</td>
            <td>{{ item.gjdq }}</td>
            <td>{{ item.gzdw }}</td>
            <td>{{ item.zw }}</td>
            <td>{{ item.zc }}</td>
            <td>{{ item.zygzjs }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {getUserWork} from "@/api/userInfo_user.js";
export default {
  props: {
    sfzjh: String,
  },
  data() {
    return {workList: []};
  },
  mounted() {
    getUserWork(this.sfzjh).then((res) => {
      if (res.status) {
        this.workList = res.data;
      }
    });
  },
};
</script>

<style></style>

// import store from '@/state/store'
export default [{
    path: '/',
    name: 'log',
    component: () => import('../views/pages/account/login'),
    // meta: {
    //     beforeResolve(routeTo, routeFrom, next) {
    //         // If the user is already logged in
    //         if (store.getters['auth/loggedIn']) {
    //             // Redirect to the home page instead
    //             next({ name: 'home' })
    //         } else {
    //             // Continue to the login page
    //             next()
    //         }
    //     },
    // },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/pages/account/register'),
    // meta: {
    //     beforeResolve(routeTo, routeFrom, next) {
    //         // If the user is already logged in
    //         if (store.getters['auth/loggedIn']) {
    //             // Redirect to the home page instead
    //             next({ name: 'home' })
    //         } else {
    //             // Continue to the login page
    //             next()
    //         }
    //     },
    // },
  },
  {
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () => import('../views/pages/account/forgot-password'),
    // meta: {
    //     beforeResolve(routeTo, routeFrom, next) {
    //         // If the user is already logged in
    //         if (store.getters['auth/loggedIn']) {
    //             // Redirect to the home page instead
    //             next({ name: 'home' })
    //         } else {
    //             // Continue to the login page
    //             next()
    //         }
    //     },
    // },
  },
  {
    path: '/forgot-password2',
    name: 'Forgot-password2',
    component: () => import('../views/pages/account/forgot-password2'),
    // meta: {
    //     beforeResolve(routeTo, routeFrom, next) {
    //         // If the user is already logged in
    //         if (store.getters['auth/loggedIn']) {
    //             // Redirect to the home page instead
    //             next({ name: 'home' })
    //         } else {
    //             // Continue to the login page
    //             next()
    //         }
    //     },
    // },
  },
  {
    path: '/find-password',
    name: 'Find-Password',
    component: () => import('../views/pages/account/find-password'),
  },
  {
    path: '/weekPassword',
    name: 'week-password',
    component: () => import('../views/pages/account/week-password'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    // meta: {
    //     authRequired: true,
    //     beforeResolve(routeTo, routeFrom, next) {
    //         if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    //             store.dispatch('auth/logOut')
    //         } else {
    //             store.dispatch('authfack/logout')
    //         }
    //         const authRequiredOnPreviousRoute = routeFrom.matched.some(
    //             (route) => route.push('/login')
    //         )
    //         // Navigate back to previous page, or home as a fallback
    //         next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
    //     },
    // },
  },
  /*
  浏览器要求
  */
  {
    path: '/browser',
    name: 'browser',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/account/browser')
  },
  {
    path: '/pages/coming-soon',
    name: 'Coming-soon',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/utility/coming-soon')
  },


  {
    path: '/pages/maintenance',
    name: 'Maintenance',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/utility/maintenance')
  },

  {
    path: '/pages/error-404',
    name: 'Error-404',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/utility/error-404')
  },
  {
    path: '/pages/error-500',
    name: 'Error-500',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/utility/error-500')
  },
  // 新闻详情
  {
    path: '/admin/announcementDetails',
    name: 'announcementDetails',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/common/announcementDetails')
  },
  {
  path: "*", // 此处需特别注意置于最底部
  redirect: "/pages/error-404"
}
]

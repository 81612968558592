import {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest
} from "@/libs/axios";



// 获取用户基本信息
export const userInfo = (sfzjh) => {
    return getRequest(`/yethan/userInfo/${sfzjh}`);
};
// 修改用户基本信息
export const updateUserInfo = (sfzjh,params) => {
    return putRequest(`/yethan/userInfo/save/${sfzjh}`, params);
};
export const updateUserInfoSfzjh = (sfzjh,params) => {
    return putRequest(`/yethan/userInfo/sfzjh/${sfzjh}`, params);
};
// 修改用户联系电话
export const updateUserphone = params => {
    return patchRequest("/yethan/userInfo/saveMobilePhone", params);
};
// 修改用户邮箱
export const updateUserEmail = params => {
    return patchRequest("/yethan/userInfo/saveEmail", params);
};
// 修改用户密码
export const updateUserPassword = params => {
    return patchRequest("/yethan/userInfo/savePassword", params);
};

// 用户信息审核
export const handleUserInfo = (sfzjh, params)=>{
    return patchRequest(`/yethan/userInfo/${sfzjh}/handle`, params)
}

// 获取审核状态
export const getHandles = (sfzjh)=>{
    return getRequest(`/yethan/userInfo/handle/${sfzjh}`)
}

// 图片审核
export const handleUserPhone = (sfzjh, params)=>{
    return patchRequest(`/yethan/userInfo/${sfzjh}/userInfoPhoto/handle`, params)
}



// 更新家庭成员信息
export const updateUserFamily = params => {
    return putRequest("/yethan/userInfo/saveUserInfoFamily", params);
};
// 查询家庭成员信息
export const getUserFamily = (sfzjh) => {
    return getRequest(`/yethan/userInfo/${sfzjh}/userInfoFamily`);
};
// 删除家庭成员信息
export const deleteUserFamily = params => {
    return deleteRequest("/yethan/userInfo/deleteUserInfoFamily/" + params);
};

// 更新个人工作经历信息
export const updateUserWork = params => {
    return putRequest("/yethan/userInfo/saveUserInfoWork", params);
};
// 查询工作经历信息
export const getUserWork = (sfzjh) => {
    return getRequest(`/yethan/userInfo/${sfzjh}/userInfoWork`);
};
// 删除工作经历信息
export const deleteUserWork = params => {
    return deleteRequest("/yethan/userInfo/deleteUserInfoWork/" + params);
};

//  更新个人教育经历
export const updateUserEdu = params => {
    return putRequest("/yethan/userInfo/saveUserInfoEducation", params);
};
// 查询个人教育经历
export const getUserEdu = (sfzjh) => {
    return getRequest(`/yethan/userInfo/${sfzjh}/userInfoEducation`);
};

// 查询个人教育经历
export const getUserEduDetails = (sid) => {
    return getRequest(`/yethan/userInfo/userInfoEducation/${sid}`);
};

export const userEduHandleBatch = (sid, status) => {
    return patchRequest(`/yethan/userInfo/edu/handle/batch/${sid}`, {xlshzt: status});
};

export const userPhotoHandleBatch = (sid, status) => {
    return patchRequest(`/yethan/userInfo/img/handle/batch/${sid}`, {zjshzt: status});
};

export const deleteUserEdu = sid => {
    return deleteRequest(`/yethan/userInfo/userInfoEducation/${sid}`);
};

export const deleteUserPhoto = sid => {
    return deleteRequest(`/yethan/userInfo/userInfoPhoto/${sid}`);
};



// 审核个人教育经历
export const handleUserEdu = (sfzjh, params) => {
    return patchRequest(`/yethan/userInfo/${sfzjh}/userInfoEducation/handle`, params);
};




// 上传用户证件照
export const saveStandardPhoto = params => {
    return postRequest("/yethan/userInfo/saveStandardPhoto", params);
};
// 上传身份证证件照
export const saveIdentificationPhoto = params => {
    return postRequest("/yethan/userInfo/saveIdentificationPhoto", params);
};

// 获取用户证件照
export const getStandardPhoto = sfzjh => {
    return getRequest(`/yethan/userInfo/${sfzjh}/userInfoPhoto/`);
};


export const getCheckPhoto = sid => {
    return postRequest(`/yethan/userInfo/img/check/${sid}`);
};


// 保存学历证明
export const saveXLZM = params => {
    return putRequest("/yethan/userInfo/saveUserInfoEducationXlzm", params);
};


export const state = {
    sfzjh:'',
    info:{}
  }

  export const getters = {}
  
  export const mutations = {
    Info(state,info) {
        state.info = info
    },
    Sfzjh(state,sfzjh) {
        state.sfzjh = sfzjh
    },
  }
  
  export const actions = {
    changeInfo({commit},{info}){
        commit('Info',info)
    },
    changesfzjh({commit},{sfzjh}){
        commit('Sfzjh',sfzjh)
    },
  }
  
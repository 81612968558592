import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import vco from "v-click-outside"


import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import ElementUI from 'element-ui';
import download from './plugins/download'
import {
  ExportSavePdf,
  ExportSavePdf2
} from "@/libs/common"
import {
  getStore,
  setStore,
} from '@/libs/storage';
import "@/assets/css/iconfont/iconfont.css"
import "@/assets/scss/app.scss";
import 'element-ui/lib/theme-chalk/index.css';
import {
  getBm
} from "@/api/common/public.js"
import VueUeditorWrap from 'vue-ueditor-wrap'
import {
  initFirebaseBackend
} from './helpers/firebase/authUtils';

import {
  configureFakeBackend
} from './helpers/fakebackend/fake-backend';
import {
  getUserType,
  timeSlice,
  isMobile
} from "./libs/common.js"

import {
  formatDate,
  formatDateDetails
} from "./libs/utils";
import yzNotice from "./components/notice/notice.js";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}



// let base = "http://222.209.91.218:8084";
// let fileBase = "http://222.209.91.218:8085";
let base = "https://ersadminapi.jdpta.com";
let fileBase = "https://ersfile.jdpta.com";
// let base = "http://127.0.0.1:8083";
// let fileBase = "http://192.168.0.101";

// let base = "https://ersadminapi.ix2.com";
// 跳转考生账号
let registerBase = "https://www.jdpta.com";

// let registerBase = "http://localhost:8080";
// let fileBase = "http://192.168.0.125:8080";
let baseWpsUrl = "";
let webSocketUrl = "ws://ersadminapi.jdpta.com";
Vue.prototype.REGISTER_URL = registerBase;
Vue.prototype.FILE_URL = fileBase;
Vue.prototype.API_URL = base;
Vue.prototype.WEB_SOCKET_URL = webSocketUrl;
Vue.prototype.BASE_URL = fileBase + "/yethan";
Vue.prototype.BASE_FILE_URL = fileBase;
Vue.prototype.BASE_WPS_URL = baseWpsUrl;
Vue.prototype.formatDate = formatDate;
Vue.prototype.formatDateDetails = formatDateDetails;
Vue.prototype.getStore = getStore
Vue.prototype.setStore = setStore
Vue.prototype.checkPowerList = checkPowerList
Vue.prototype.BaseConfig = JSON.parse(window.localStorage.getItem('BaseConfig'))
Vue.prototype.accessToken = getStore('accessToken');
Vue.prototype.getUserType = getUserType;
Vue.prototype.timeSlice = timeSlice;
Vue.prototype.isMobile = isMobile;
Vue.prototype.ExportSavePdf = ExportSavePdf
Vue.prototype.ExportSavePdf2 = ExportSavePdf2
Vue.prototype.loginAdminAccount = loginAdminAccount

Vue.prototype.getFileUrl = function(fileId, isBase64 = false,phoneCode) {
  if (isBase64) {
    return getRequest(`/yethan/file/download/base64/${fileId}`, {
      isBase64: true
    }, false)
  } else {
    return `${base}/yethan/file/download/${fileId}?ytoken=${getStore('accessToken')}&phoneCode=${phoneCode}`
  }
}
var userInfo = getStore('userInfo');
if (userInfo) {
  Vue.prototype.userInfo = JSON.parse(userInfo)
}
// 下载文件
Vue.prototype.$download = download
Vue.config.productionTip = false
import AdminUserInfoShow from '@/components/user/admin_user/admin-user-info-show.js'
import UserShow from '@/components/user/register_user/user-show.js'
import {
  checkPowerList
} from '@/api/admin/exam/checkPower'
import ImportModal from '@/components/export/importModal.js'
import ExportModal from '@/components/export/exportModal.js'
import ImportZhengshu from '@/components/export/zhengshuImport.js'
import YzUpload from '@/components/form/yzUpload/yzUpload.js'
import ImgDialog from "@/components/img/imgDialog";
import {
  getRequest
} from "./libs/axios";
import {
  loginAdminAccount
} from "@/api/admin/user/institutionManage";
import Print from "@/assets/js/htmlToPdf.js"
Vue.use(Print)
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(yzNotice)
Vue.use(AdminUserInfoShow)
Vue.use(UserShow)
Vue.use(ImportModal)
Vue.use(ExportModal)
Vue.use(ImportZhengshu)
Vue.use(ImgDialog)
Vue.use(require('vue-chartist'))
Vue.use(ElementUI)
Vue.use(YzUpload)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)
// 全局注册富文本组件
Vue.component('vue-ueditor-wrap', VueUeditorWrap)
Date.prototype.format = function(fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
}

// 预置的编码方法
Vue.prototype.getBm = (module, prefix) => {
  return getBm(module, {
    prefix: prefix
  }).then(res => {
    if (res.status) {
      return res.data.bmStr
    }
  })
}

Vue.prototype.setUEditorConfig = function () {
  Vue.prototype.UEditorConfig = {
    UEDITOR_HOME_URL: '/ueditor/', // 需要令此处的URL等于对应 ueditor.config.js 中的配置。
    serverUrl: Vue.prototype.API_URL + '/yethan/file/saveFileUeditor?module=Web&ytoken=' + getStore('accessToken'),
    imageActionName: 'saveFileUeditor',
    imageFieldName: 'files',
    imageMaxSize: 2048000,
    imageAllowFiles: ['.png', '.jpg', '.jepg', '.gif', '.bmp'],
    imageUrlPrefix: Vue.prototype.BASE_FILE_URL, //图片访问路径前缀
    /* 上传视频配置 */
    videoActionName: 'saveFileUeditor', /* 执行上传视频的action名称 */
    videoFieldName: "files", /* 提交的视频表单名称 */
    videoUrlPrefix: Vue.prototype.BASE_FILE_URL, /* 视频访问路径前缀 */
    videoMaxSize: 102400000, /* 上传大小限制，单位B，默认100MB */
    videoAllowFiles: [
      ".mp4"
    ], /* 上传视频格式显示 */

    /* 上传文件配置 */
    fileActionName: "saveFileUeditor",
    /* controller里,执行上传视频的action名称 */
    fileFieldName: "files",
    /* 提交的文件表单名称 */
    fileMaxSize: 51200000,
    /* 上传大小限制，单位B，默认50MB */
    fileAllowFiles: [
      ".png", ".jpg", ".jpeg", ".gif", ".bmp",
      ".flv", ".swf", ".mkv", ".avi", ".rm", ".rmvb", ".mpeg", ".mpg",
      ".ogg", ".ogv", ".mov", ".wmv", ".mp4", ".webm", ".mp3", ".wav", ".mid",
      ".rar", ".zip", ".tar", ".gz", ".7z", ".bz2", ".cab", ".iso",
      ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".pdf", ".txt", ".md", ".xml"
    ],
    "fileUrlPrefix": Vue.prototype.BASE_FILE_URL, //图片访问路径前缀
  }
}

Vue.prototype.setUEditorConfig()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

<template>
  <div>
    <div>
      <div class="mt-2 mb-2">
        <i class="iconfont icon-ze-user-circle-o mr-2"></i>基本用户信息
      </div>
      <div style="display: flex " class="w-100">
        <div style="width: 80%" class="flexList fs-xs">
          <div class=" msgContent flexList no-border-bottom" style="flex-wrap: wrap;">
            <div class="flexList w-50 " :class="'w-' + v.width" v-for="(v, i) in bounds" :key="i"
              style="height: 30px; line-height: 30px">
              <div class="msgName ">{{ v.label }}</div>
              <div class="msgVal flexList">
                <div class="line1 use-circle" v-if="v.type == Boolean">
                  {{ form[v.bound] ? "是" : "否" }}
                </div>
                <div class="line1 use-circle" v-else>{{ form[v.bound] }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="guding-img">
          <img
              style="width: 120px; height: 150px"
              :src="
                  userPhoto.bzz
                ?  getFileUrl(userPhoto.bzz)
                : require('@/assets/images/person/person-none.png')
            "
              alt="加载失败"
          />
        </div>
      </div>
      <div class="flexList fs-xs">
        <div class="flexList msgContent no-border-top w-100" style="flex-wrap: wrap">
          <div class="flexList w-50 " :class="'w-' + v.width" v-for="(v, i) in bounds2" :key="i"
            style="height: 30px; line-height: 30px">
            <div class="msgName ">{{ v.label }}</div>
            <div class="msgVal flexList">
              <div class="line1 use-circle" v-if="v.type == Boolean">
                {{ form[v.bound] ? "是" : "否" }}
              </div>
              <div class="line1 use-circle" v-else>{{ form[v.bound] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    userInfo
  } from "@/api/userInfo_user.js";
  import {getStandardPhoto} from "@/api/userInfo_user";
  export default {
    props: {
      sfzjh: String,
    },
    data() {
      return {
        form: {},
        userPhoto: {},
        bounds: [{
            label: "姓名",
            bound: "xm",
            width: "50"
          },
          {
            label: "性别",
            bound: "xb",
            width: "50"
          },
          {
            label: "证件类型",
            bound: "sfzjlxmc",
            width: "50"
          },
          {
            label: "证件号码",
            bound: "sfzjh",
            width: "50"
          },
          {
            label: "籍贯",
            bound: "jg",
            width: "50"
          },
          {
            label: "出生地",
            bound: "csdmc",
            width: "50"
          },
          {
            label: "民族",
            bound: "mzmc",
            width: "50"
          },
          {
            label: "政治面貌",
            bound: "zzmmmc",
            width: "50"
          },
          {
            label: "最高学历",
            bound: "zgxlmc",
            width: "50"
          },
          {
            label: "最高学位",
            bound: "zgxwmc",
            width: "50"
          },
        ],
        bounds2: [{
            label: "毕业学校",
            bound: "zhbyxxmc",
            width: "40"
          },
          {
            label: "毕业专业",
            bound: "zhbyzy",
            width: "60"
          },
          {
            label: "工作单位",
            bound: "xgzdw",
            width: "40"
          },
          {
            label: "所在单位职务",
            bound: "szdwzw",
            width: "60"
          },
          {
            label: "注册时间",
            bound: "createTime",
            width: "40"
          },
          {
            label: "照片实名状态",
            bound: "zpzt",
            type: Boolean,
            width: "60"
          },
          {
            label: "联系电话",
            bound: "yddh",
            width: "40"
          },
          {
            label: "电子邮箱",
            bound: "dzxx",
            width: "60"
          },
        ],
      };
    },
    mounted() {
      userInfo(this.sfzjh).then((res) => {
        if (res.status) {
          this.form = res.data;
          this.showUser = true;
        }
      });
      getStandardPhoto(this.sfzjh).then((res) => {
        if (res.status) {
          this.userPhoto = res.data || {};
        }
      });
    },
  };
</script>

<style scoped>
  .msgContent {
    border-right: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
  }

  .guding-img {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
  }

  .no-border-top {
    border-top: unset !important;
  }

  .no-border-bottom {
    border-bottom: unset !important;
  }

  .msgName {
    min-width: 160px;
    width: 160px;
  }
</style>

<template>
<el-image
    id="elImg"
    ref="elImg"
    v-show="false"
    :src="imgUrl"
    :preview-src-list="srcList"
  >
  </el-image>
  <!-- <el-dialog ref="elDialog"
      :visible.sync="showImg"
             :show-close="true"
  >
    <img :src="imgUrl" alt="" style="max-width: calc(100vw - 200px)">
  </el-dialog> -->
</template>

<script>
import {getFileType} from "@/api/common/public";
import $ from "jquery";
export default {
  name: "imgDialog",
  data(){
    return {
      showImg: false,
      imgUrl: "",
      srcList: [],
    }
  },
  methods: {
    show(img){
      this.imgUrl = ''
      var split = img.split('?')[0].split('/');
      getFileType(split[split.length - 1]).then((res)=>{
        if (res.status){
          if (res.message == 'img'){
            this.srcList = [];
            this.imgUrl = img;
            this.srcList.push(img);
            this.showImg = true;
            if ($("#elImg")[0]) {
              $("#elImg").click();
            } else {
              let timer = setInterval(() => {
                if ($("#elImg")[0]) {
                  $("#elImg").click();
                  clearInterval(timer)
                }
              }, 500);
            }
          } else {
            window.open(img, '_blank')
          }
        }

      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-dialog__header{
  padding: 0;
}
/deep/ .el-dialog__body{
  padding: 0;
  text-align: center;
}
/deep/ .el-dialog, .el-pager li{
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
}
/deep/ .el-dialog{
  width: auto;
  display: inline-block;
}
.el-dialog__wrapper{
  text-align: center;
}
</style>
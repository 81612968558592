<template>
  <input id="upload_id" type="file" multiple="multiple"  @change="uploadFile" style="display: none">
</template>

<script>
import {uploadFiles} from "@/api/common/public";
import $ from "jquery";

export default {
  name: "yzUpload",
  data(){
    return {
      module:"",
      callback: null,
    }
  },
  methods: {
    startUpload(module, callback){
      this.module = module
      this.callback = callback
      $('#upload_id').click()
    },
    uploadFile(ev){
      var files = ev.target.files;
      for (let i = 0; i < files.length; i++) {
        uploadFiles(this.module, files[i]).then(res=>{
          if (res.status){
            const fileInfo = res.data[0]
            this.callback(fileInfo)
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
<!-- 导出弹窗-->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<script>
  import exportFields from "@/components/export/exportFields";
  import MessageCodeModal from "@/components/codeVerification/messageCodeModal.vue";
  import {
    getPhone
  } from "@/api/index.js"
  /**
   * Dashboard component
   */
  export default {
    name: "exportModal",
    components: {
      exportFields,
      MessageCodeModal
    },
    model: {
      event: "input",
      data: "form"
    },
    data() {
      return {
        showHandle: false,
        form: {},
        excelForm: {},
        title: "",
        type: "",
        module: "",
        ksbmbh: "",
        condition: {},
        wheres: [],
        values: [],
        keyParam: "",
        keyWord: "",
        userPhone: "",
        showmodal: false,
        isVerified: false,
        isBmzyb: false,
        noExport: ['keyWord', 'keyParam', 'pageNum', 'pageSize', 'keyword', 'total', 'keyName']
      };
    },
    methods: {
      show(conf) {
        this.showHandle = true
        this.title = conf.title
        this.type = conf.type
        this.module = conf.module
        this.ksbmbh = conf.ksbmbh
        this.form = {}
        this.wheres = []
        this.values = []
        this.keyParam = ""
        this.keyName = ""
        this.keyWord = ""
        this.isVerified = false
        if (conf.module == "BMZYB") {
          this.isBmzyb = true
        } else {
          this.isBmzyb = false
        }

        if (conf.condition) {
          this.condition = {}
          this.condition = JSON.parse(JSON.stringify(conf.condition))
          for (let i in this.condition) {
            if (!this.noExport.filter(val => {
                return val == i
              })[0]) {
              this.wheres.push(i)
              this.values.push(this.condition[i])
            }

          }
          if (this.condition.keyWord || this.condition.keyword) {
            this.keyWord = this.condition.keyWord ? this.condition.keyWord : this.condition.keyword
            this.keyParam = this.condition.keyParam ? this.condition.keyParam : this.condition.keyName
          }
        }

      },
      hide() {
        this.showHandle = false
      },
      exportAddParam(val) {
        if (val) {
          this.startDownload(val)
        }


      },
      isBmzybExport() {
        if (this.isBmzyb) {
          this.getExportCode()
        } else {
          this.startDownload()
        }
      },
      // 开始导出
      startDownload(val) {
        let url = this.$refs.exportFields.download(val)
        if (this.isBmzyb) {
          this.showHandle = true
          setTimeout(this.getExportCode(), 500)
        } else {
          this.showHandle = false
        }
        window.open(url, '_blank')
      },
      // 获取导出验证
      getExportCode() {
        var _this = this
        getPhone().then(res => {
          // console.log(res)
          if (res.status) {
            _this.userPhone = res.data
            _this.$refs.messageCode.getCodeAgain(_this.userPhone)
          }
        })

      }
    },
    mounted() {},
    destroyed() {
      this.showHandle = false
    }

  };
</script>

<template>
  <div>
    <MessageCodeModal ref="messageCode" :showmodal="showmodal" :userPhone="userPhone" :isForExport="true"
      @exportAddParam="exportAddParam"></MessageCodeModal>

    <b-modal v-model="showHandle" centered :title="title" size="xl" title-class="font-18" hide-footer>
      <div class="mb-2">
        <exportFields :module="module" v-model="excelForm" :type="type" :ksbmbh="ksbmbh" :filename="title"
          :wheres="wheres" :values="values" :keyWord="keyWord" :keyParam="keyParam" ref="exportFields"></exportFields>
      </div>
      <div class="text-center">
        <!--    <el-button type="primary" size="medium" class="mr-2" @click="getExportCode" v-if="isBmzyb">获取导出验证码</el-button> -->
        <el-button type="primary" size="medium" @click="isBmzybExport()">确认并导出</el-button>
      </div>
    </b-modal>
  </div>
</template>

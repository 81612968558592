import {
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "@/libs/axios";
import {
  getStore,
  setStore
} from "@/libs/storage";
import {

  loginJump,

} from "@/api/index.js"

// 查询机构用户分页
export const getUserManagement = (yhlb, params) => {
  params['yhlb'] = yhlb;
  return getRequest(`/yethan/UserManagement/info/listPage`, params);
};

// 增加机构用户
export const addUserManagement = (params) => {
  return postRequest("/yethan/UserManagement", params);
};

// 删除机构用户假删除
export const deleteUserManagement = (yhzh, sczt) => {
  return patchRequest(`/yethan/UserManagement/info/${yhzh}/sczt`, {
    sczt: sczt,
  });
};

// 可用状态更新
export const updateUserManagementKyzt = (yhzh, kyzt) => {
  return patchRequest(`/yethan/UserManagement/info/${yhzh}/kyzt`, {
    kyzt: kyzt,
  });
};

//更新机构用户
export const updateUserManagement = (yhzh, params) => {
  return putRequest(`/yethan/UserManagement/info/${yhzh}`, params);
};

// 获取机构用户详情
export const getUserManagementDetails = (yhzh) => {
  return getRequest(`/yethan/UserManagement/info/${yhzh}`);
};

// 获取用户基本详情
export const getUserDetails = (yhzh) => {
  return getRequest(`/yethan/UserManagement/${yhzh}`);
};

// 更新权限
export const updatePermission = (mid, params) => {
  return patchRequest(`/yethan/UserManagement/${mid}/permissions`, params);
};

// 更新密码
export const updatePassword = (yhzh, mm) => {
  return patchRequest(`/yethan/UserManagement/${yhzh}/password`, {
    mm: mm
  });
};

// 获取统计
export const getStatisticalPage = (params) => {
  return getRequest(`/yethan/UserManagement/info/statistical/listPage`, params)
}

// 用户审核
export const handleUserManagement = (yhzh, params) => {
  return patchRequest(`/yethan/UserManagement/info/${yhzh}/shzt`, params);
}
// 用户删除
export const deleteBatchManagement = (yhzh) => {
  return deleteRequest(`/yethan/UserManagement/batch/${yhzh}`);
}
// 当前单位管理下的人员
export const userManagementPageExistDw = (params) => {
  return getRequest(`/yethan/UserManagement/info/exists/dw/listPage`, params);
}
export const qualificationAuditStatistics = (params) => {
  return getRequest(`/yethan/registerUser/qualificationAuditStatistics`, params);
}

export const loginAdminAccount = (yhzh) => {
  getRequest(`/yethan/UserManagement/loginAdminAccount`, {
    yhzh: yhzh
  }).then(res => {
    if (res.status) {
      setStore("superUserInfo", getStore("userInfo"));
      setStore("userInfo", res.data)
      setStore("accessToken", res.data.token)
      if (res.data) {
        if (res.data.roleNum == 2 || res.data.roleNum == 3) { //机构用户
          window.location.href = '/home';
        } else {
          window.location.href = '/admin'
        }

      } else {
        window.location.href = '/';
      }
    }
  })
}

// 证书库管理
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "@/libs/axios";
// 分页查询
export const getZsk = params => {
  return getRequest("/yethan/cert/bank/listPage", params);
};
// 添加
export const addZsk = params => {
  return postRequest("/yethan/cert/bank", params);
};
// 修改
export const editZsk = params => {
  return putRequest("/yethan/cert/bank", params);
};
// 删除
export function deleteZsk(zskid) {
  return deleteRequest("/yethan/cert/bank/" + zskid);
}
// 详情
export function getZskInfo(zskid) {
  return getRequest("/yethan/cert/bank/" + zskid);
}

// 清空证书库
export function clearZsk(zskid) {
  return deleteRequest("/yethan/cert/bank/clear/" + zskid);
}

//物流公司

// 分页查询
export const getWlgs = params => {
  return getRequest("/yethan/cert/logistics/listPage", params);
};
// 添加
export const addWlgs = params => {
  return postRequest("/yethan/cert/logistics", params);
};
// 修改
export const editWlgs = params => {
  return putRequest("/yethan/cert/logistics", params);
};
// 删除
export function deleteWlgs(sid) {
  return deleteRequest("/yethan/cert/logistics/" + sid);
}

<!-- 添加证书库 -->
<script>
  import {
    easyList,
    SecondEasyList
  } from "@/api/admin/base/mainType.js"
  
  export default {
    props: {
      title: String,
      bound: Array,
    },
    data() {
      return {
        addForm: {},
        showmodal: false,
        years: [],
        secondList: [],
        classList: [],
        mb10: [{
          'margin-bottom': '10px'
        }],
      };
    },
    methods: {
      show(data) {
        this.years=[]
        this.getYear()
        this.getClassListPage()
        this.getSecondList()
        if (data) {
          console.log(data)
          let copyData = JSON.parse(JSON.stringify(data))
          this.addForm = copyData
        } else {
          this.addForm = {}
        }
        this.showmodal = true
      },
      hide() {
        this.showmodal = false
      },
      submit() {
        this.$emit("submit", this.addForm)
      },
      // 考试类别
      getClassListPage() {
        easyList().then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },
      // 二级分类
      getSecondList(params) {
        SecondEasyList(params).then(res => {
          if (res.status) {
            this.secondList = res.data
          }
        })
      },
      getflmc() {
        let val = this.addForm.flbm
        let obj = this.secondList.find(k => {
          return k.flbm === val
        })
        this.addForm.flmc = obj.flmc

      },
      getlbmc() {
        let val = this.addForm.lbbm
        let obj = this.classList.find(k => {
          return k.lbbm === val
        })
        this.addForm.lbmc = obj.lbmc
        this.getSecondList({
          lbbm: val
        });
      },
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
    },
    mounted() {

    }
  }
</script>

<template>
  <b-modal id="addZhengshu" v-model="showmodal" :title="title" centered title-class="font-18" hide-footer>
    <div class="zskModal">
      <el-form ref="addForm" :model="addForm">
        <el-form-item :style="mb10">
          <div class="d-flex check-distri "><label>所属类别：</label>
            <div class=" col-sm-10 p-0 flexList">
              <select name="lbbm" class="form-control form-select w-100" v-model="addForm.lbbm" @change="getlbmc">
                <option value="" disabled>请选择所属类别</option>
                <option :value="item.lbbm" v-for="(item,index) in classList" :key="index">{{item.lbmc}}</option>
              </select>

            </div>
          </div>
        </el-form-item>
        <el-form-item :style="mb10">
          <div class="d-flex check-distri "><label>所属分类：</label>
            <div class="col-sm-10  p-0 flexList">
              <select name="" class="form-control form-select w-100" v-model="addForm.flbm" @change="getflmc">
                <option value="" disabled>请选择分类</option>
                <option :value="item.flbm" v-for="(item,index) in secondList" :key="index">{{item.flmc}}</option>
              </select>
            </div>
          </div>
        </el-form-item>
        <el-form-item :style="mb10">
          <div class="d-flex check-distri "><label>所属年份：</label>
            <div class="col-sm-10  p-0 flexList">
              <select name="" class="form-control form-select w-100" v-model="addForm.nf">
                <option v-for="(item, i) in years"  :value="item.value" :key="i">{{item.value}}
                </option>
              </select>
            </div>
          </div>
        </el-form-item>

        <el-form-item :style="mb10">
          <div class="d-flex check-distri "><label>证书库名：</label>
            <div class="col-sm-10 p-0 flexList">
              <input type="text" placeholder="请输入证书库名" v-model="addForm.zskmc" class="form-control w-100 h30 " />

            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3" @click="submit">确定</button>
      <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
    </div>


  </b-modal>
</template>

<style>
  .check-distri label {
    margin-bottom: 0;
  }
</style>

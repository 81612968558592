<template>
  <b-modal
    v-model="showUser"
    centered
    title="用户信息查看"
    size="xl"
    title-class="font-18"
    hide-footer
  >
    <div style="height: 500px; overflow-y: auto">
      <base-info :sfzjh="sfzjh"></base-info>
      <image-info :sfzjh="sfzjh"></image-info>
      <edu-info :sfzjh="sfzjh"></edu-info>
      <family-info :sfzjh="sfzjh"></family-info>
      <work-info :sfzjh="sfzjh"></work-info>
    </div>
    <div class="mt-3 text-center">
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import BaseInfo from "./base-info.vue";
import ImageInfo from "./img-info.vue";
import EduInfo from "./edu-info.vue";
import FamilyInfo from "./family-info.vue";
import WorkInfo from "./work-info.vue";
export default {
  name: "user-show.vue",
  components: {
    BaseInfo,
    ImageInfo,
    EduInfo,
    FamilyInfo,
    WorkInfo,
  },
  data() {
    return {
      showUser: false,
      sfzjh: "",
    };
  },
  methods: {
    show(sfzjh) {
      this.sfzjh = sfzjh;
      this.showUser = true;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <select
      v-if="!readOnly"
      class="form-control form-select"
      @change="selectChange($event, bound)"
      @click="clickChange($event, bound)"
    >
      <option
        :value="item[boundDict[0]]"
        v-for="(item, i) in listData"
        :key="i"
        :disabled="disableObj[item[boundDict[0]]]"
        :selected="form[bound[0]] == item[boundDict[0]]"
        :class="{ 'text-warning': disableObj[item[boundDict[0]]] }"
      >
        {{ item[boundDict[1]] }}
        {{ disableObj[item[boundDict[0]]] ? "不可选" : "" }}
      </option>
    </select>
    <div
      v-else
      :value="item[boundDict[0]]"
      v-for="(item, i) in listData"
      :key="i"
    >
      <template
        v-if="form[bound[0]] == item[boundDict[0]] && form[bound[0]] != ''"
      >
        {{ (bound[0] == 'qxdm' || bound[0] == 'hjszdm'|| bound[0] == 'csdm')? item[boundDict[1]].split('|')[2]:item[boundDict[1]] }}
      </template>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { getRequest } from "@/libs/axios";
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    readOnly: {
      default: false,
    },
    disableList: {
      default: () => {
        return [];
      },
    },
    dict: {
      type: Array,
      required: false,
    },
    bound: Array,
    form: Object,
    boundDict: {
      type: Array,
      default: function () {
        return ["value", "name"];
      },
    },
    url: {
      type: String,
      required: false,
      default: function () {
        return null;
      },
    },
    params: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    filter: {
      type: String,
      required: false,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      listData: [],
      disableObj: {},
    };
  },
  methods: {
    clickChange(e, v) {
      this.selectChange(e, v);
    },
    selectChange(e, v) {
      let bound = this.boundDict;
      let options = $(e.target).find("option");
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          for (const index in this.bound) {
            if (index < 2) {
              this.form[v[index]] = this.listData[i][bound[index]];
            } else {
              let [k1, v1] = this.bound[index].split(":");
              this.form[k1] = this.listData[i].details[v1];
            }
          }
          this.$emit("change", this.listData[i]);
        }
      }
      this.$emit("input", this.form);
    },
    updateData() {
      if (this.listData) {
        let seleted = this.listData.find((item) => {
          return this.form[this.bound[0]] == item[this.boundDict[0]];
        });
        if (!seleted && this.listData.length > 0) {
          for (const key in this.listData) {
            if (
              this.listData[key] &&
              !this.disableObj[this.listData[key][this.boundDict[0]]]
            ) {
              seleted = this.listData[key];
              break;
            }
          }
        }
        if (seleted) {
          let bound = this.boundDict;
          for (const index in this.bound) {
            if (index < 2) {
              this.form[this.bound[index]] = seleted[bound[index]];
            } else {
              let [k1, v1] = this.bound[index].split(":");
              this.form[k1] = seleted.details[v1];
            }
          }
        }
      }
    },
  },
  watch: {
    dict: {
      deep: true,
      handler(newData) {
        this.listData = JSON.parse(JSON.stringify(newData));
        if (
          !this.listData.filter((v) => {
            return v.value == "";
          })[0]
        ) {
          this.listData.unshift({
            name: "请选择",
            value: "",
          });
        }
        // this.listData = this.listData.filter((item) => {
        //   return item.name.indexOf("请选择") != 0;
        // });
        // this.listData.unshift({
        //   name: "请选择",
        //   value: "",
        // });
      },
    },
    params: {
      deep: true,
      handler(params) {
        getRequest(this.url, params).then((res) => {
          if (res.status) {
            this.listData = res.data;
            this.$emit("out-filter", this.listData);
            this.updateData();
          }
        });
      },
    },
    form: {
      deep: true,
      handler(form) {
        let f = this.filter;
        if (this.filter != null) {
          this.listData = this.dict.filter((item) => {
            return !item.details || item.details[f] == form[f];
          });
        }
        this.$emit("out-filter", this.listData);
        this.updateData();
      },
    },
    disableList: {
      immediate: true,
      deep: true,
      handler(val) {
        val.forEach((v) => {
          this.disableObj[v] = v;
        });
      },
    },
  },
  mounted() {
    if (this.url) {
      getRequest(this.url, this.params).then((res) => {
        if (res.status) {
          this.listData = res.data;
          this.updateData();
        }
      });
    } else {
      if (this.bound[0] == "bkgwxkm") {
      }
      if (this.dict) {
        this.listData = JSON.parse(JSON.stringify(this.dict));
        if (
            !this.listData.filter((v) => {
              return v.value == "";
            })[0]
          ) {
            this.listData.unshift({
              name: "请选择",
              value: "",
            });
          }
        // this.listData = this.listData.filter((item) => {
        //   return item.name.indexOf("请选择") != 0;
        // });
        // this.listData.unshift({
        //   name: "请选择",
        //   value: "",
        // });
        this.updateData();
      }
    }
  },
};
</script>
<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>

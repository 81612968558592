import {
  postNoAuthRequest,
  getNoAuthRequest,
  getRequest,
  postRequest,
  patchRequest
} from "@/libs/axios";
import {
  setStore
} from "@/libs/storage.js"
/**/

// 用于发送获取图片验证码
export const getcode = params => {
  return getNoAuthRequest("/yethan/code", params);
};

// 用于发送获取手机验证码
export const sendPhoneCode = params => {
  return getNoAuthRequest("/yethan/sendPhoneCode", params);
};

// 用于发送获取手机验证码
export const sendUserPhoneCode = params => {
  return getRequest("/yethan/UserRegister/sendPhoneCode", params);
};


// 用于注册发送获取手机验证码
export const sendPhoneCodeNotUse = params => {
  return getNoAuthRequest("/yethan/sendPhoneCodeNotUse", params);
};

// 用于使用账号密码登录
export const userLogin = params => {
  return postNoAuthRequest("/yethan/login", params);
};

// 用于使用手机验证码登录
export const phoneLogin = params => {
  return postNoAuthRequest("/yethan/phoneLogin", params);
};

// 用于登出
export const loginOut = params => {
  return getRequest(`/yethan/loginOut`, params);
};

// 注册用户
export const userRegister = params => {
  return postNoAuthRequest("/yethan/register", params);
};
// 校验算数验证码
export const checkCode = params => {
  return getRequest("/yethan/checkCode", params);
};
// // 找回密码
// export const findPassword = params => {
//   return postRequest(`/yethan/findPassword`, params);
// };

//考生用户找回密码校验短信验证码
export const checkMobileCode = params => {
  return getNoAuthRequest(`/yethan/checkMobileCode`, params);
};

// 机构用户密码修改
export const updataPassword = params => {
  return getRequest(`/yethan/updataPassword`, params);
};
export const postN = params => {
  return postNoAuthRequest(``, params);
};
// 修改弱密码
export const updateStrongPassword = params => {
  return patchRequest("/yethan/userInfo/savePasswordStrong", params);
};
//管理端找回密码获取手机验证码
export const findPasswordPhoneCode = params => {
  return getNoAuthRequest("/yethan/findPasswordPhoneCode", params);
};
//管理端用户找回密码
export const findPassword = params => {
  return postNoAuthRequest("/yethan/findPassword", params);
};




// 全局监听Enter事件，并且执行传入的函数
export const listenEnterEv = function(evFunc) {
  document.onkeydown = function(ev) {
    if (ev.key === "Enter") {
      evFunc();
    }
  }
}

// 获取固定导出手机号
export const getPhone = params => {
  return getRequest("/yethan/registerExportPhoneNumber", params, false);
};

// 手机验证码是否过期
export const codeExpired = (params) => {
  return getRequest("/yethan/UserRegister/checkPhoneCode", params, false);
};
// 手机验证码是否过期
export const userCodeExpired = () => {
  return getRequest("/yethan/UserRegister/checkUserPhoneCode", {}, false);
};

// 系统参数
export const initSystem = (params) => {
  return getRequest("/yethan/sysConfig/webCommon", params, false);
};

// 上传文件校验
export const checkFile = (_this, ext, file) => {
  var fileExt = file.name.split(".")[file.name.split(".").length - 1];
  var sclx = file.name.indexOf("." + fileExt)
  if (sclx == -1) {
    _this.$notify.warning({
      message: "上传文件类型错误"
    });
    return false;
  } else {
    this.$notify.success({
      message: "上传成功"
    });
    return true
  }
};
// 判断是否为管理员或机构用户，跳转对应主页
export const loginJump = (_this, userInfo) => {
  if (userInfo) {
    if (userInfo.roleNum == 2 || userInfo.roleNum == 3) { //机构用户

      _this.$router.push('/home');

    } else {
      _this.$router.push('/admin');
    }

  } else {
    _this.$router.push('/');
  }

}


//文件大小转换单位
export const unitsChange = (size) => {
  if ((size / 1024) < 1) {
    return size + 'b'
  } else if ((size / 1024 / 1024) < 1) {
    return Math.round(size / 1024) + 'kb'
  } else if ((size / 1024 / 1024 / 1024)) {
    return Math.round(size / 1024 / 1024) + 'Mb'
  }
}

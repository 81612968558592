<!-- 短信验证码弹窗-->
<template>
  <div>
    <!-- 弹窗开始-->
    <!-- 获取短信验证 -->
    <b-modal id="register" v-model="isModalShow" title="获取短信验证码" title-class="font-18" centered class="person-modal"
      hide-footer>
      <div class="input-group" style="height: 45px">
        <div class="input-group-prepend">
          <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
              src="@/assets/images/person/icon/security.png" alt="" /></span>
        </div>
        <el-input id="code" type="text" placeholder="请输入验证码" class="form-control h-100" v-model="userPhoneCode"
          @change="getPhoneCode" />
        <button type="button" id="code_button" class="btn btn-info h-100 verification-code"
          @click="getCodeAgain()">获取短信码</button>
      </div>
      <div class="text-center mt-2 h40" style="clear: both;"><button type="button" class="btn btn-info w-sm"
          @click="isCodeForLogin">确定</button></div>
    </b-modal>


    <!-- 算式验证码 -->
    <b-modal id="imgCheck" v-model="isShow" title="获取短信验证码" title-class="font-18" centered class="person-modal"
      hide-footer>
      <div class="input-group" style="height: 45px">
        <div class="input-group-prepend">
          <span class="input-group-text icon-box"><img src="@/assets/images/person/icon/security.png" alt="" /></span>
        </div>
        <el-input id="code" type="text" placeholder="请输入算式验证码" class="form-control h-100" v-model="verification" />
        <img :src="imgCode.img" class="check-img" @click="getImageCode" />
      </div>
      <div class="w-100 mt-2">
        <button type="button" class="btn btn-info change-btn" @click="getCode">
          获取短信验证码
        </button>
      </div>
    </b-modal>
    <!-- 弹窗结束-->
  </div>


</template>
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-input__inner {
    border: none;
    height: 100%;
    line-height: 0;
  }
</style>
<script>
  import {
    getcode,
    phoneLogin,
    sendPhoneCode,
    loginJump,
    codeExpired,
  } from "@/api/index.js"
  import $ from 'jquery'
  import {
    setStore
  } from "@/libs/storage.js"
  /**
   * Dashboard component
   */
  export default {
    name: "messageCodeModal",
    components: {},
    model: {
      prop: "showmodal",
      event: "input",
    },
    props: {
      showmodal: {
        type: Boolean,
        default: false,
      },
      userPhone: String,
      isForExport: {
        type: Boolean,
        default: false,
      },
      mmqd: Number
    },
    data() {
      return {
        isShow: false, //图形验证弹窗
        isModalShow: this.showmodal,
        userPhoneCode: "",
        verification: "",
        showImageCheck: false, //是否显示图形验证
        imgCode: {
          uuid: "",
          img: "",
          code: "",
        },
        timer: null
      };
    },
    destroyed() {
      clearInterval(this.timer)
    },
    methods: {
      // show(conf) {
      //   this.showmodal = true

      // },
      // hide() {
      //   this.showmodal = false
      // },
      /* 发送手机验证码*/
      sendPhoneCode: function(formDate) {
        return sendPhoneCode(formDate);
      },
      // 验证码失效，重新获取验证码
      getCodeAgain(phone) {
        if (this.showImageCheck || this.isForExport) {
          this.userPhoneCode = ""
          this.verification = ""
          this.isModalShow = true
          let parma = phone ? phone : ""
          this.isCodeExist(parma)

        }
      },
      /*获取验证码*/
      getCode: function() {
        // this.checkPhone(); //验证手机号码(机构用户的手机号从后端读取，不需要走这步)
        var _this = this
        if (this.userPhone) {
          /*如果状态正确*/
          var formDate = {
            mobilePhone: this.userPhone,
            uuid: this.imgCode.uuid,
            code: this.verification,
          }
          this.committingPhoneCode = true
          this.sendPhoneCode(formDate).then(res => {
            if (res.status) {
              _this.$notify({
                title: '成功',
                message: res.message,
                type: 'success'
              });
              _this.resetCode(60); //倒计时
              this.isShow = false
              this.isModalShow = true
              return
            } else {
              this.$message({
                title: '警告',
                message: "验证码发送次数过多，请稍后再试！",
                type: 'warning'
              })
              if (!this.showImageCheck) {
                _this.resetCode(5);
              } else {
                this.verification = ""
                this.getImageCode();
              }


            }

          })
        } else {
          $('#phone').focus();
        }

      },
      getPhoneCode(val) {
        this.userPhoneCode = val
      },
      // 验证码是否过期
      isCodeExist(phone) {
        let mobilePhone = phone ? phone : this.userPhone
        codeExpired({
          mobilePhone: mobilePhone
        }).then((res) => {
          if (res.status) {
            if (res.data) { //已过期可重发
              this.getImageCode();
              this.isShow = true;
            } else {

              this.$confirm("验证码尚未过期, 是否重新获取?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                .then(() => {
                  this.getImageCode();
                  this.isShow = true;
                })
                .catch(() => {
                  return;
                });
            }
          }
        });
      },
      // 获取算式验证码
      getImageCode: function() {
        var _this = this;
        getcode().then((res) => {
          if (res.status) {
            _this.imgCode.uuid = res.data.uuid;
            _this.imgCode.img = res.data.img;
          }
        });
      },

      //倒计时
      resetCode: function(second) {
        var _this = this;
        clearInterval(_this.timer);
        _this.timer = setInterval(function() {
          second -= 1;
          if (second > 0) {
            $("#code_button").css("cursor", "no-drop");
            $("#code_button").text(second + "秒");
            _this.showImageCheck = false
          } else {
            clearInterval(_this.timer);
            $("#code_button").css("cursor", "pointer");
            $("#code_button").text("重新发送验证码");
            _this.showImageCheck = true
            _this.committingPhoneCode = false;
          }
        }, 1000);
      },

      // 是登录还是导出的验证码
      isCodeForLogin() {
        if (this.isForExport) {
          if (this.userPhoneCode) {
            this.$emit("exportAddParam", this.userPhoneCode)
            this.isModalShow = false
          }
        } else {
          this.userPhoneLogin()
        }
      },
      // 验证码登录(与tab切换的短信登录稍有些不同，单独写一下)
      userPhoneLogin() {
        let form = {
          mobilePhone: this.userPhone,
          phoneCode: this.userPhoneCode,
        }
        phoneLogin(form).then(res => {
          if (res.status) {
            // 成功之后才存localstorage
            setStore("accessToken", res.data.token);
            setStore("userInfo", res.data);
            if (this.mmqd == 0) {
              this.$router.push("./weekPassword")
              return
            }
            loginJump(this, res.data)
          }
        })
      },


    },
    watch: {
      showmodal: {
        handler(val) {
          if (val != this.isModalShow) {
            this.isModalShow = val
          }
        }
      },
      isModalShow: {
        handler(val) {
          this.$emit("input", val);
        }
      }

    },


  };
</script>

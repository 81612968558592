<template>
  <div v-if="eduList">
    <div class="blue-font mt-2 mb-2">
      <i class="iconfont icon-ze-user-circle-o mr-2"></i>学习经历
    </div>
    <div class="msgContent flexList fs-xs">
      <table class="table mb-0">
        <thead class="thead-light">
          <tr>
            <th>序号</th>
            <th style="width:170px;">起止时间</th>
            <th>国家地区</th>
            <th width="15%">学校名称</th>
            <th width="15%">所学专业</th>
            <th>获得学历</th>
            <th>获得学位</th>
            <th>审核状态</th>
            <th>证明材料</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in eduList" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td><div class="line1">{{ item.rxny }}至{{ item.jsxyny }}</div></td>
            <td><div class="line1" :title="item.gjdqmc">{{ item.gjdqmc }}</div></td>
            <td><div class="line1" :title="item.byyxxhdw">{{ item.byyxxhdw }}</div></td>
            <td><div class="line1" :title="item.sxzymc">{{ item.sxzymc }}</div></td>
            <td><div class="line1" :title="item.xlmc+ ' ' +item.xlzsh">{{ item.xlmc }}</div>{{ item.xlzsh }}</td>
            <td><div class="line1" :title="item.byyxxhdw+ ' ' +item.xwzsh">{{ item.hdxwmc }}</div>{{ item.xwzsh }}</td>
            <td>
              <div class="line1">
                <span v-if="item.xlshzt">
                  <i v-if="item.xlshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                  <i v-else-if="item.xlshzt == 2" class="iconfont icon-ze-clear text-danger "></i>
                  <i v-else class="iconfont icon-icon_minus-circled"></i>
                </span>
                <span v-else>
                  <i v-if="item.xlxtrzzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                  <i v-else-if="item.xlxtrzzt == 2" class="iconfont icon-ze-clear text-danger "></i>
                  <i v-else class="iconfont icon-icon_minus-circled"></i>
                </span>
              </div>
            </td>
            <td>
              <div class="line1" v-if="item.xlzm">
                <a href="javascript: void(0);" @click="$yzImgDialog().show(getFileUrl(item.xlzm))">查看</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {getUserEdu} from "@/api/userInfo_user.js";
export default {
  props: {
    sfzjh: String,
  },
  data() {
    return {
      eduList: [],
    };
  },
  mounted() {
    getUserEdu(this.sfzjh).then((res) => {
      if (res.status) {
        this.eduList = res.data;
      }
    });
  },
};
</script>

<style scoped>
  .msgContent {
    border-right: 0px;
  }
</style>

import Vue from "vue"
import {
  loginOut
} from "@/api/index.js"
import $ from 'jquery'
import {
  getStore,
  setStore,
  removeStore
} from './storage';
import Cookies from 'js-cookie';
import {
  Loading
} from 'element-ui';
export const formatDateDetailsToDay = (date) => {
  var date = new Date(date)
  return date.getFullYear() + "-" + formatInteger(date.getMonth() + 1) + "-" + date.getDate()
}

let loading

export function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

export function endLoading() {
  loading.close()
}


export const formatInteger = (num, digit) => {
  var numSting = String(num)
  if (numSting.length >= digit) {
    return numSting;
  } else {
    var prefix = ""
    for (let i = 0; i < digit - numSting.length; i++) {
      prefix += "0"
    }
    return prefix + numSting
  }
}

export const formatDateTime = (datea) => {
  var date = new Date(Date.parse(datea));
  //alert(date);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  // var second = date.getSeconds();
  // second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute;
}

// dateTime with seconds
export const formatWithSeconds = (datea) => {
  var date = new Date(Date.parse(datea));
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  var second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
}

export const getUserType = (number) => {
  let obj = {
    "0": "登录用户",
    "1": "考生、注册用户",
    "2": "基层审核员",
    "3": "主管部门管理员、机构用户",
    "4": "考点管理员（高校、中小学等）",
    "8": "考点管理员（高校、中小学等）",
    "9": "管理员（考试基地工作人员）",
    "10": "系统管理员",

  }
  return obj[number]
}

export const checkImgExists = (imgurl) => {
  return new Promise((resolve, reject) => {
    let ImgObj = new Image();
    ImgObj.src = imgurl;
    ImgObj.onload = function(res) {
      resolve(ImgObj);
    }
    ImgObj.onerror = function(err) {
      reject(err)
    }
  })
}
export const GetRequest = (url) => {
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}


//退出登录
export const logOut = () => {
  removeStore("accessToken");
  removeStore("userInfo");
  removeStore("requestTime");
  window.location = "/login"
}

// 截取日期
export const timeSlice = (time) => {
  let sTime = time ? time : ""
  if (sTime) {
    return sTime.substring(0, 10)
  }
}

/**
 *
 * @param fn {Function}   实际要执行的函数
 * @param delay {Number}  延迟时间，也就是阈值，单位是毫秒（ms）
 *
 * @return {Function}     返回一个“去弹跳”了的函数
 */
export const debounce = (fn, delay) => {

  // 定时器，用来 setTimeout
  var timer

  // 返回一个函数，这个函数会在一个时间区间结束后的 delay 毫秒时执行 fn 函数
  return function() {

    // 保存函数调用时的上下文和参数，传递给 fn
    var context = this
    var args = arguments

    // 每次这个返回的函数被调用，就清除定时器，以保证不执行 fn
    clearTimeout(timer)

    // 当返回的函数被最后一次调用后（也就是用户停止了某个连续的操作），
    // 再过 delay 毫秒就执行 fn
    timer = setTimeout(function() {
      fn.apply(context, args)
    }, delay)
  }
}

//文件大小转换单位
export const unitsChange = (size) => {
  if ((size / 1024) < 1) {
    return size + 'b'
  } else if ((size / 1024 / 1024) < 1) {
    return Math.round(size / 1024) + 'Kb'
  } else if ((size / 1024 / 1024 / 1024)) {
    return Math.round(size / 1024 / 1024) + 'Mb'
  }
}

/* // 导出页面为PDF格式
import html2canvas from "html2canvas"
import JSPDF from "jspdf"
export const ExportSavePdf = (htmlTitle, id, type, isPrint) => {
  // type来判断横版还是竖版
  var element = document.getElementById(id)
  //滚轮置顶，避免留白
  window.pageYOffset = 0;
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
  html2canvas(element, {
    logging: false,
  }).then(function(canvas) {
    //转化为本地的图片地址
    let imgURL = canvas.toDataURL("image/jpeg", 1.0);
    let w = canvas.width
    let h = canvas.height

    let a4Height = 841.89
    let a4Width = 595.28
    //一页pdf显示html页面生成的canvas高度;
    var pageHeight;
    //未生成pdf的html页面高度
    var leftHeight = h;
    //页面偏移
    var position = 0;
    var pdf
    //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
    var imgWidth
    var imgHeight
    if (type == "1") {
      pdf = new JSPDF("l", "pt", "a4"); // l 表示横向导出；true表示压缩pdf，否则文件会很大
      imgWidth = a4Height;
      imgHeight = (imgWidth / w) * h;
      pageHeight = (w / a4Height) * a4Width

    } else {
      pdf = new JSPDF("p", "pt", "a4"); //竖版
      imgWidth = a4Width;
      imgHeight = (imgWidth / w) * h;
      pageHeight = (w / a4Width) * a4Height

    }


    //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(a4Height)
    //当内容未超过pdf一页显示的范围，无需分页
    if (leftHeight < pageHeight) {
      pdf.addImage(imgURL, "JPEG", 0, 0, imgWidth, imgHeight);

    } else {
      // 分页
      while (leftHeight > 0) {
        pdf.addImage(
          imgURL,
          "JPEG",
          0,
          position,
          imgWidth,
          imgHeight
        );
        leftHeight -= pageHeight;
        if (type == 1) {
          position -= a4Width - 25
        } else {
          position -= a4Height;
        }


        //避免添加空白页
        if (leftHeight > 0) {
          pdf.addPage();
        }
      }
    }
    // pdf.output('dataurlnewwindow');
    // let currentTime = new Date().getTime()
    // pdf.save(htmlTitle + currentTime)
    if (isPrint == true) { //打印
      let currentTime = new Date().getTime()
      pdf.save(htmlTitle + currentTime)
    } else { //预览
      pdf.output('dataurlnewwindow');
    }


  })
}
 */

// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

export const ExportSavePdf = (htmlTitle, ids, type, isPrint, closeId) => {
  startLoading()
  let idList = ids.split(',')
  let index = 0
  var title = htmlTitle //DPF标题
  let PDF
  if (type == "1") {
    PDF = new JsPDF("l", "mm", "a4"); // l 表示横向导出；true表示压缩pdf，否则文件会很大
  } else {
    PDF = new JsPDF("p", "mm", "a4"); //竖版
  }
  Canvas(idList, index, PDF, title, type, isPrint, closeId)
}
const Canvas = (idList, index, PDF, title, type, isPrint, closeId) => {
  if (!idList[index]) {
    if (closeId) {
      $("#" + closeId).css("display", "none")
    }
    endLoading()
    if (isPrint === false) { //预览
      PDF.autoPrint();
      // PDF.output('dataurlnewwindow');
      window.open(PDF.output('bloburl'), '_blank');
    } else { //打印
      PDF.save(title + '.pdf')

    }
    return false
  }
  if (index != 0) {
    PDF.addPage()
  }
  let height = Math.floor($('#' + idList[index]).outerHeight());
  let width = Math.floor($('#' + idList[index]).outerWidth());
  if (type == "1") {
    let divisor = Math.floor(width / 297 * 210)
    let num = Math.ceil(height / divisor);
    $("#" + idList[index]).css({
      height: num * divisor + "px",
    });
  } else {
    let divisor = Math.floor(width / 210 * 297)
    let num = Math.ceil(height / divisor);
    $("#" + idList[index]).css({
      height: num * divisor + "px",
    });
  }
  html2Canvas(document.querySelector('#' + idList[index]), {
    allowTaint: true,
    taintTest: false,
    useCORS: true,
    y: 0, // 对Y轴进行裁切
    dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
    scale: 4 //按比例增加分辨率
  }).then(function(canvas) {
    // $("#" + idList[index]).css({
    //   height: 'auto',
    // }); //改回原来高度
    let contentWidth = canvas.width
    let contentHeight = canvas.height
    let pageHeight = Math.floor(contentWidth / 210 * 297)
    let leftHeight = contentHeight
    let position = 0
    let imgWidth
    let imgHeight
    let pageData = canvas.toDataURL('image/jpeg', 1.0)
    if (type == "1") {
      imgWidth = 297;
      imgHeight = (297 / contentWidth) * contentHeight;
      pageHeight = Math.floor(contentWidth / 297 * 210)
    } else {
      imgWidth = 210;
      imgHeight = (210 / contentWidth) * contentHeight;
    }
    if (leftHeight < pageHeight) {
      PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
    } else {
      while (leftHeight > 0) {

        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        leftHeight -= pageHeight
        if (type == "1") {
          position -= 210
        } else {
          position -= 297
        }
        if (leftHeight > 0) {
          PDF.addPage()
        }
      }
    }

    index++
    Canvas(idList, index, PDF, title, type, isPrint, closeId)

  })
}



export const ExportSavePdf2 = async (htmlTitle, ids, type) => {
  startLoading()
  let idList = ids.split(',')
  let index = 0
  let title = htmlTitle //DPF标题
  let PDF
  if (type == "1") {
    PDF = new JsPDF("l", "mm", "a4"); // l 表示横向导出；true表示压缩pdf，否则文件会很大
  } else {
    PDF = new JsPDF("p", "mm", "a4"); //竖版
  }

    return await Canvas2(idList, index, PDF, title, type)


}
const  Canvas2 = async (idList, index, PDF, title, type) => {


  if (index != 0) {
    PDF.addPage()
  }
  let height = Math.floor($('#' + idList[index]).outerHeight());
  let width = Math.floor($('#' + idList[index]).outerWidth());
  if (type == "1") {
    let divisor = Math.floor(width / 297 * 210)
    let num = Math.ceil(height / divisor);
    $("#" + idList[index]).css({
      height: num * divisor + "px",
    });
  } else {
    let divisor = Math.floor(width / 210 * 297)
    let num = Math.ceil(height / divisor);
    $("#" + idList[index]).css({
      height: num * divisor + "px",
    });
  }
    return await html2Canvas(document.querySelector('#' + idList[index]), {
      allowTaint: true,
      taintTest: false,
      useCORS: true,
      y: 0, // 对Y轴进行裁切
      dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
      scale: 4 //按比例增加分辨率
    }).then(function(canvas) {
      // $("#" + idList[index]).css({
      //   height: 'auto',
      // }); //改回原来高度
      let contentWidth = canvas.width
      let contentHeight = canvas.height
      let pageHeight = Math.floor(contentWidth / 210 * 297)
      let leftHeight = contentHeight
      let position = 0
      let imgWidth
      let imgHeight
      let pageData = canvas.toDataURL('image/jpeg', 1.0)
      if (type == "1") {
        imgWidth = 297;
        imgHeight = (297 / contentWidth) * contentHeight;
        pageHeight = Math.floor(contentWidth / 297 * 210)
      } else {
        imgWidth = 210;
        imgHeight = (210 / contentWidth) * contentHeight;
      }
      if (leftHeight < pageHeight) {
        PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
      } else {
        while (leftHeight > 0) {

          PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
          leftHeight -= pageHeight
          if (type == "1") {
            position -= 210
          } else {
            position -= 297
          }
          if (leftHeight > 0) {
            PDF.addPage()
          }
        }
      }

      const imgDom = document.createElement('img')
      imgDom.src = pageData;
      imgDom.style.width = '920px'
      document.querySelector('#' + idList[index]).innerHTML = '';
      document.querySelector('#' + idList[index]).append(imgDom)
      index++

      // Canvas(idList, index, PDF, title, type, isPrint, closeId)
      return PDF
    })

}


/**
 * 判断访问类型是PC端还是手机端
 */
export const isMobile = () => {
  var userAgentInfo = navigator.userAgent;

  var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

  var mobile_flag = false;

  //根据userAgent判断是否是手机
  for (var v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      mobile_flag = true;
      break;
    }
  }

  var screen_width = window.screen.width;
  var screen_height = window.screen.height;

  //根据屏幕分辨率判断是否是手机
  if (screen_width < 500 && screen_height < 800) {
    mobile_flag = true;
  }

  return mobile_flag;
}

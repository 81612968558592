<template>
  <b-modal
    v-model="showUser"
    centered
    title="用户信息查看"
    size="lg"
    title-class="font-18"
    hide-footer
  >
    <div v-for="(v1, i1) in bounds" :key="i1">
      <div class="mt-2 mb-2">
        <i class="iconfont icon-ze-user-circle-o mr-2"></i>{{ i1 }}
      </div>
      <div class="msgContent flexList fs-xs">
        <div class="flexList" style="flex-wrap: wrap; padding-left: 0">
          <div
            class="flexList w-50"
            :class="'w-' + v.width"
            v-for="(v, i) in v1"
            :key="i"
            style="height: 30px; line-height: 30px"
          >
            <div class="msgName label-required">{{ v.label }}</div>
            <div class="msgVal flexList">
              <div class="line1 use-circle" v-if="v.type == Boolean">
                {{ form[v.bound] ? "是" : "否" }}
              </div>
              <div class="line1 use-circle" v-else>{{ form[v.bound] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 text-center">
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {getUserDetails} from "@/api/admin/user/institutionManage";

export default {
  name: "admin-user-info-show.vue",
  data() {
    return {
      showUser: false,
      form: {},
      bounds: {
        基本用户信息: [
          {label: "所属机构", bound: "jgmc", width: "50"},
          {label: "所属单位", bound: "dwmc", width: "50"},
          {label: "姓名", bound: "xm", width: "50"},
          {label: "出生日期", bound: "csrq", width: "50"},
          {label: "证件号码", bound: "sfzjh", width: "50"},
          {label: "职务", bound: "zw", width: "50"},
          {label: "登录账号", bound: "yhzh", width: "50"},
          {label: "用户类别", bound: "yhlb", width: "50"},
          {label: "所在省市", bound: "ssmc", width: "50"},
          {label: "所在地区", bound: "djsmc", width: "50"},
          {label: "移动电话", bound: "yddh", width: "50"},
          {label: "办公地址", bound: "bgdz", width: "50"},
          {label: "可用状态", bound: "kyzt", type: Boolean, width: "50"},
          {label: "删除状态", bound: "sczt", type: Boolean, width: "50"},
          {label: "审核状态", bound: "shzt", type: Boolean, width: "50"},
          {label: "审核备注", bound: "shbz", width: "50"},
          {label: "审核人", bound: "shrxm", width: "50"},
          {label: "审核时间", bound: "shsj", width: "50"},
          {label: "最后编辑", bound: "modifyTime", width: "100"},
        ],
        登录账户信息: [
          {label: "系统ID", bound: "sid", width: "50"},
          {label: "用户账号", bound: "yhzh", width: "50"},
          {label: "登录密码", bound: "xm", width: "50"},
          {label: "用户类别", bound: "yhlb", width: "50"},
          {label: "用户角色", bound: "yhjs", width: "50"},
          {label: "微信ID", bound: "wxid", width: "50"},
          {label: "邮箱地址", bound: "dzxx", width: "50"},
          {label: "移动电话", bound: "yddh", width: "50"},
          {label: "QQ号码", bound: "qq", width: "50"},
          {label: "用户描述", bound: "yhms", width: "50"},
          {label: "最后登录", bound: "zhdl", width: "50"},
          {label: "是否锁定", bound: "sfsd", type: Boolean, width: "50"},
          {label: "锁定原因", bound: "sdsm", width: "100"},
          {label: "用户头像", bound: "yhtx", width: "50"},
          {label: "微信头像", bound: "wxtx", width: "50"},
          {label: "创建时间", bound: "createTime", width: "50"},
          {label: "最后编辑", bound: "modifyTime", width: "50"},
          {label: "用户权限", bound: "mkqx", width: "100"},
        ],
      },
    };
  },
  methods: {
    show(yhzh) {
      getUserDetails(yhzh).then((res) => {
        if (res.status) {
          this.form = res.data;
          this.showUser = true;
        }
      });
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="fs-xs border-gray">
    <div class="w-100 msgLabel ">
      <div class="export-tip w-100 text-left pl-2">可导出字段</div>
    </div>
    <div>

    </div>
    <div class="p-2 " style="min-height: 50px; max-height: 600px;overflow-y: auto;">
      <div>
        <el-checkbox :indeterminate="isIndeterminate" @change="handleCheckAllChange" v-if="module!='BMZYB'">全选
        </el-checkbox>
        <div v-else>
          <el-checkbox :indeterminate="isIndeterminate" @change="handleCheckAllChange" class="ml-3">
            全选所有字段
          </el-checkbox>
          <el-checkbox :indeterminate="isIndeterminate2" @change="handleCheckAll" class="ml-3">
            全选不带码的字段
          </el-checkbox>

        </div>



      </div>
      <div>
        <el-checkbox-group v-model="checkedDbFields" v-if="module!='BMZYB'">
          <el-checkbox class="w-30 h40" :label="dbField.dbColumnName" v-for="(dbField, i) in dbFields" :key="i">
            {{dbField.dbColumnName}}
            <div class=" d-inline-flex field_intro ml-2" style="line-height: 19px;" :title="dbField.dbColumnMemo">
              ({{dbField.dbColumnMemo}})</div>
          </el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group v-model="checkedDbFields" v-else>
          <el-checkbox class="w-30 h40" :label="dbField.zddm" v-for="(dbField,index) in topFileds" :key="'info1'+index">
            {{dbField.zddm}}
            <div class=" d-inline-flex field_intro ml-2" style="line-height: 19px;" :title="dbField.zdmc">
              ({{dbField.zdmc}})
            </div>
          </el-checkbox>
          <div class="divide"></div>
          <div>
            <el-checkbox class="w-30 h40" :label="dbField.zddm" v-for="(dbField, index) in centerFileds"
              :key="'info'+index">
              {{dbField.zddm}}
              <div class=" d-inline-flex field_intro ml-2" style="line-height: 19px;" :title="dbField.zdmc">
                ({{dbField.zdmc}})</div>
            </el-checkbox>
          </div>
          <div class="divide"></div>
          <div>
            <el-checkbox class="w-30 h40" :label="dbField.zddm" v-for="(dbField, index) in newStatusFields"
              :key="'info2'+index">
              {{dbField.zddm}}
              <div class=" d-inline-flex field_intro ml-2" style="line-height: 19px;" :title="dbField.zdmc">
                ({{dbField.zdmc}})</div>
            </el-checkbox>
          </div>

        </el-checkbox-group>
      </div>

    </div>

  </div>
</template>
<style>
  .export-tip {
    background-color: #F3F5FB;
  }

  .border-gray {
    border: 1px solid #DFDFDF;
  }

  .divide {
    margin: 10px 0;
    border-bottom: 1px #DFDFDF dashed;
  }

  .field_intro {
    width: 215px;
    height: 40px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
</style>
<script>
  import {
    exportItem,
    sureExport,
    exportBmkFields
  } from '@/api/common/public'

  export default {
    name: "exportFields",
    props: {
      module: String,
      form: Object,
      type: String,
      filename: String,
      ksbmbh: String,
      wheres: Array,
      values: Array,
      keyWord: String,
      keyParam: String,
    },
    model: {
      prop: "form",
      event: "input"
    },
    data() {
      return {
        chooseList: [],
        choose: {},
        checkAll: false,
        isIndeterminate: false,
        isIndeterminate2: false,
        dbFields: [],
        subData: {},
        checkedDbFields: [],
        topFileds: [],
        centerFileds: [],
        newDbFileds: [],
        statusFields: [],
        newStatusFields: [],
        hasKsbmbhList: [{
            name: "CJDAK",
          },
          {
            name: "WJK",
          },
          {
            name: "HMDK",
          },
          // {
          //   name: "CJK"
          // },
          {
            name: "ZWK"
          },
          {
            name: "BMZYB"
          }
        ],
      }
    },
    methods: {
      chooseField(e, v) {
        console.log(this.choose)
      },
      change() {
        this.$emit("input", this.form)
      },
      handleCheckAllChange(e) {
        this.isIndeterminate = e
        if (this.module == "BMZYB") {

          this.checkedDbFields = e ? this.dbFields.map(item => {
            return item.zddm
          }) : []
        } else {
          this.checkedDbFields = e ? this.dbFields.map(item => {
            return item.dbColumnName
          }) : []
        }


      },
      // 不带码
      handleCheckAll(e) {
        this.isIndeterminate2 = e

        let newDbFileds = []
        newDbFileds = this.newDbFileds
        this.checkedDbFields = e ? newDbFileds.map(item => {
          return item.zddm
        }) : []



      },
      // 获取字段
      getFieldByModule() {
        exportItem(this.module).then(res => {
          if (res.status) {
            this.dbFields = res.data
          }
        })
      },
      // 如果是报名表
      getBmkFileds() {
        exportBmkFields(this.ksbmbh).then(res => {
          if (res.status) {
            let registerFields = res.data.registerFields
            // 上面字段
            this.topFileds = registerFields.filter(k => {
              return k.xszd != null
            })
            // 中间字段
            this.centerFileds = registerFields.filter(k => {
              return k.xszd == null
            })

            // 系统字段

            this.statusFields = res.data.statusFields
            this.statusFields.forEach(k => {
              if (k.zdmc.endsWith("码")) {
                this.centerFileds.push(k)

              }
            })
            this.newStatusFields = this.statusFields.filter(v => {
              return !v.zdmc.endsWith("码")
            })
            console.log(this.newStatusFields, 11)

            this.dbFields.push(...registerFields, ...this.statusFields)
            this.dbFields.forEach(k => {
              if (!this.centerFileds.filter(v => {
                  return v.zddm == k.zddm
                })[0]) {
                this.newDbFileds.push(k)
              }
            })

          }
        })
      },
      download(val) {
        let keyList = this.checkedDbFields.join(",")
        this.subData = {
          keyList: keyList,
          templateCode: this.module,
          wheres: this.wheres,
          values: this.values,
          keyParam: this.keyParam,
          keyWord: this.keyWord,

        }
        let getName = this.hasKsbmbhList.find(k => {
          return k.name == this.module
        })
        if (getName) {
          this.subData.ksbmbhParam = this.ksbmbh
          if (this.module == "BMZYB" && val) {
            this.subData.phoneCode = val
          }
        }
        return sureExport(this.type, this.filename, this.subData)
      }
    },
    mounted() {
      if (this.module == "BMZYB") {
        this.getBmkFileds()
      } else {
        this.getFieldByModule()
      }
      if (this.module == "ZSYJ") {
        this.checkedDbFields = ['xm', 'sfzh', 'zsh', 'dz', 'yb', 'ksxx', 'jb', 'zy', 'ddh', 'yddh', 'ydh']
      }
    },
    components: {},
    watch: {

      form: {
        immediate: true,
        deep: true,
        handler(form) {
          this.chooseList = []
          for (let k in form) {
            this.chooseList.push(k)
          }
        }
      },
      checkedDbFields(e) {
        this.isIndeterminate = (e.length == this.dbFields.length)

      }
    }
  }
</script>

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest
} from "@/libs/axios";
import Vue from 'vue'
import {
  patchRequest
} from "@/libs/axios";
import {
  getStore
} from "../../../libs/storage";
// 审核权限
export const checkPowerListOld = [{
    name: "报名资格审核",
    id: "1"
  },
  {
    name: "不允许修改审核意见",
    id: "5"
  },
  {
    name: "报名照片审核",
    id: "2"
  },
  {
    name: "学历审核",
    id: "3"
  },
  {
    name: "导出报名审核",
    id: "4"
  },
  {
    name: "锁定审核",
    id: "6"
  },
  {
    name: "减免申请",
    id: "7"
  },
  {
    name: "任务分配",
    id: "8"
  },
  {
    name: "查看手机号",
    id: "9"
  },
  {
    name: "只允许查看报名",
    id: "10"
  },
  {
    name: "职位上报",
    id: "11"
  },
  {
    name: "解除锁定审核",
    id: "12"
  },
]
// 审核类别
export const checkTypes = {
  1: "按报考单位审核",
  2: "按区域+专业审核",
  3: "按工作任务划分审核",
}
export const checkPowersToNames = function(str) {
  if (!str) {
    return ""
  }
  // console.log(str)
  var split = str.split(",");
  split = split.filter(s => {
    if (s) {
      return s
    }
  })
  return split.map(item => {
    let c = checkPowerListOld.find(i => {
      return i.id == item
    })
    if (c){
      return c.name
    }
  }).join("|")
}

export const getExportPower = function() {
  var userInfo = getStore('userInfo');
  var orgshlb = getStore('orgshlb');
  if (userInfo) {
    userInfo = JSON.parse(userInfo)
  } else {
    userInfo = {}
  }
  if (userInfo.roleNum != 9 && userInfo.roleNum != 10) {
    if (orgshlb) {
      let shlb = orgshlb
      let flag
      if (shlb.includes(',' + 4 + ',')) {
        flag = true
      } else {
        flag = false
      }
      return flag
    }
  }

}

export const getCheckType = function(num) {
  return checkTypes[num]
}
Vue.prototype.getCheckType = getCheckType
Vue.prototype.checkPowersToNames = checkPowersToNames
var userInfo = getStore('userInfo');
var orgshlb = getStore('orgshlb');
if (userInfo) {
  userInfo = JSON.parse(userInfo)
} else {
  userInfo = {}
}
let checkPowerListExport = checkPowerListOld
if (userInfo.roleNum != 9 && userInfo.roleNum != 10) {
  checkPowerListExport = checkPowerListOld.filter(i => {
    return orgshlb.includes(',' + i.id + ',') && i.id!=8
  })
}
export const checkPowerList = checkPowerListExport
// 获取审核用户分页
export const getCheckPowerPage = (params) => {
  return getRequest('/yethan/checkPower/listPage', params)
}
// 增加审核用户
export const addCheckPower = (params) => {
  return postRequest('/yethan/checkPower', params)
}
// 增加审核用户批量
export const addCheckPowerBatch = (params) => {
  return postRequest('/yethan/checkPower/batch', params)
}
// 审核单位分页
export const getCheckPowerUnitsPage = (params) => {
  return getRequest('/yethan/checkPower/units/listPage', params)
}
export const deleteAll = (params) => {
  return getRequest('/yethan/checkPower/units/deleteAll', params)
}
// 删除审核单位
export const deleteCheckPowerUnits = (sids) => {
  return deleteRequest(`/yethan/checkPower/units/${sids}`, )
}

// 增加审核单位批量
export const addCheckPowerUnitsBatch = (params) => {
  return postRequest('/yethan/checkPower/units', params)
}
export const addCheckPowerUnitsDiyBatch = (dwmc, params) => {
  return postRequest(`/yethan/checkPower/units/diy/${dwmc}`, params)
}
// 删除审核单位通过单位号
export const deleteCheckPowerUnitsByDwdm = (ksbmbh, dwdm) => {
  return deleteRequest(`/yethan/checkPower/units/${ksbmbh}/${dwdm}`)
}
// 删除审核地区
export const deleteCheckPowerUnitsLocal = (ksbmbh, dwdm) => {
  return deleteRequest(`/yethan/checkPower/area/all/${ksbmbh}/${dwdm}`)
}

// 获取审核人员详情
export const getCheckPowerDetails = function(cid) {
  return getRequest(`/yethan/checkPower/${cid}`)
}

// 删除地区
export const deleteCheckPowerUnitsArea = (sids) => {
  return deleteRequest(`/yethan/checkPower/area/area/${sids}`)
}
// 删除科目
export const deleteCheckPowerUnitsClass = (sids) => {
  return deleteRequest(`/yethan/checkPower/area/class/${sids}`)
}

// 更新审核人员
export const editCheckPowerDetails = function(cid, params) {
  return putRequest(`/yethan/checkPower/${cid}`, params)
}

// 删除审核人员
export const deleteCheckPowerDetails = function(cid, params) {
  return deleteRequest(`/yethan/checkPower/${cid}`, params)
}
// 获取审核单位分页
export const getCheckPowerAreaPage = (params) => {
  return getRequest('/yethan/checkPower/area/listPage', params)
}
// 批量增加地区
export const addCheckPowerAreaBatch = (ksbmbh, dwdm, params) => {
  return postRequest(`/yethan/checkPower/area/area/${ksbmbh}/${dwdm}`, params)
}
// 批量增加科目
export const addCheckPowerClassBatch = (ksbmbh, dwdm, params) => {
  return postRequest(`/yethan/checkPower/area/class/${ksbmbh}/${dwdm}`, params)
}
// 获取范围
export const getCheckPowerRangePage = (params) => {
  return getRequest('/yethan/checkPower/range/listPage', params)
}

// 获取考试范围
export const getRegisterUserRange = (ksbmh) => {
  return getRequest(`/yethan/registerUser/range/${ksbmh}`)
}
// 获取考试范围
export const getRegisterUserDisRange = (ksbmh) => {
  return getRequest(`/yethan/registerUser/range/dis/${ksbmh}`)
}
// 更新考试类型
export const updateExamCheckType = (ksbmbh, checkType) => {
  return patchRequest(`/yethan/checkPower/updateExamCheckType`, {
    ksbmbh: ksbmbh,
    checkType: checkType
  })
}

// 审核人员获取摘要列表
export const handleList = (num, params) => {
  return getRequest(`/yethan/checkPower/handle/list/${num}`, params)
}

export const getDepartmentAndOrgList = () => {
  return getRequest(`/yethan/checkPower/units/departmentAndOrg/list`)
}
export const getDepartment = () => {
  return getRequest(`/yethan/checkPower/units/department/list`)
}
export const departmentInit = (type) => {
  return postRequest(`/yethan/checkPower/units/department/init/${type}`)
}

export const departmentPosList = (uid) => {
  return getRequest(`/yethan/checkPower/units/department/position/list`, {uid: uid})
}

export const departmentPosAllList = () => {
  return getRequest(`/yethan/checkPower/units/department/position/allList`)
}

export const addOrDeleteDepartmentPos = (params) => {
  return postRequest(`/yethan/checkPower/units/department/position/addOrDelete`, params)
}

export const deleteAllDepartmentPos = (uid, params) => {
  return deleteRequest(`/yethan/checkPower/units/department/position/delAll/${uid}`, params)
}

export const addAllDepartmentPos = (uid, params) => {
  return postRequest(`/yethan/checkPower/units/department/position/addAll/${uid}`, params)
}

export const transferAccount = (params) => {
  return postRequest(`/yethan/UserManagement/transfer/account`, params)
}

export const slefCheckDwList = (params) => {
  return getRequest(`/yethan/checkPower/slefCheckDwList`, params)
}

export const reportStatusList = (params) => {
  return getRequest(`/yethan/checkPower/units/report/status/list`, params)
}
export const reportStatusFastComit = (params) => {
  return patchRequest(`/yethan/checkPower/units/report/status/fast`, params)
}
export const reportProfessionalList = (params) => {
  return getRequest(`/yethan/checkPower/units/report/status/professional/list`, params)
}
export const addProfessional = (params) => {
  return postRequest(`/yethan/checkPower/units/report/status/professional`, params)
}
export const deleteProfessional = (sid) => {
  return deleteRequest(`/yethan/checkPower/units/report/status/professional/${sid}`)
}
export const updateProfessional = (params) => {
  return putRequest(`/yethan/checkPower/units/report/status/professional`, params)
}

<template>
  <div>
    <table class="table light-table table-hover table-bordered ">
      <thead class="thead-light">
        <tr>
          <th>序号</th>
          <th v-for="(v, i) in dbFields" :key="i">{{v.dbColumnName}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(obj,index) in list" :key="index">
          <td>{{index+1}}</td>
          <td v-for="(v, i) in dbFields" :key="i">{{obj[bounds[v.dbColumnName]]}} </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  /**
   * Dashboard component
   */
  export default {
    name: "importModal",
    props: {
      list: Array,
      dbFields: Array,
      checkedDbFields:Array
    },
    data(){
      return {
        showHandle: true,
        title: "导入预览",
        bounds: {},
      }
    },
    methods:{
      refresh(bounds){
        this.bounds = bounds
        this.$forceUpdate()
      }
    },
    mounted(){
    }
  };
</script>

<style>
</style>

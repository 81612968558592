<!-- 导入弹窗-->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-upload-list {
    display: inline-flex;
  }

  .modal-body {
    overflow-y: auto;
  }
</style>
<script>
  import importFields from "@/components/export/importFields";
  import importPreView from "@/components/export/importPreView";
  import {
    importFileCustom,
    importFileSure,
    deleteUpload,
    exportSubmit,
    checkFile,
    getUploadLimit,
    saveCustomFileds,
    getCustomFileds,
    getLatelyFile,
    analysisLatelyFile
  } from "@/api/common/public.js";

  /**
   * Dashboard component
   */
  export default {
    name: "importModal",
    components: {
      importFields,
      importPreView,
    },
    model: {
      event: "input",
      data: "form",
    },
    data() {
      return {
        showHandle: false,
        form: {},
        excelForm: {},
        excelFields: [],
        bounds: {},
        sureList: [],
        excelList: [],
        dbFields: [],
        dbChooseFields: [],
        isShowQueRen: false,
        isShowMatch: true,
        hasSelect: false,
        uploadLimit: {
          size: 104857600,
          ext: ".dbf",
        },
        fileSize: "",
        isShowDete: false, //显示撤销上传和下载错误数据
        fileId: "",
        fileList: [],
        rollBackKey: "", //撤销key
        title: "",
        type: "",
        module: "",
        ksbmbh: "",
        cjkid: "",
        zkzkid: "",
        zskid: "", //证书库id
        tid: "", //推送信息id
        totalLength: "", //总条数
        readLength: "", //已读取
        importLength: "", //成功导入
        mustArry: [],
        hasKsbmbhList: [{
            name: "CJDAK",
          },
          {
            name: "WJK",
          },
          {
            name: "HMDK",
          },
          {
            name: "ZWK",
          },
        ],
        success: null,
        historyFileId: "", //历史上传的文件id
        hasHistory: false, //是否最近有上传过文件
      };
    },
    methods: {
      show(conf) {
        if (conf.success) {
          this.success = conf.success;
        }
        if (conf.sid) {
          this.cjkid = conf.sid;
        }
        if (conf.zkzkid) {
          this.zkzkid = conf.zkzkid;
        }
        if (conf.zskid) {
          this.zskid = conf.zskid;
        }
        if (conf.tid) {
          this.tid = conf.tid;
        }
        this.title = conf.title;
        this.type = conf.type;
        this.module = conf.module;
        this.ksbmbh = conf.ksbmbh;
        this.showHandle = true;
        this.isShowQueRen = false;
        this.isShowMatch = true;
        this.form = {};
        this.fileId = "";
        this.excelList = [];
        this.excelFields = [];
        this.dbChooseFields = [];
        this.dbFields = [];
        this.mustArry = [];
        this.excelForm = {};
        this.getLately()
      },
      hide() {
        this.showHandle = false;
      },
      change(e) {
        this.hasSelect = true;
        this.bounds = e;
        this.$refs.importPreView.refresh(e);
        this.$forceUpdate();
        this.$emit("change", e);
      },
      uploadFile(file, fileList) {
        var _this = this;
        if (
          checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file.raw)
        ) {
          let upParams = {
            module: "Import",
            templateCode: this.module,
            drkid: this.getDrkId()
          };
          if (this.module == "TSMDK" || this.module == "KSZYMDK") {
            upParams.ksbmbh = this.ksbmbh;
          }

          importFileCustom(file.raw, upParams).then(async (res) => {
            if (res.status) {
              console.log(res);
              if (fileList.length > 1) {
                fileList.splice(0, 1);
              }
              let newData = res.data;
              this.fileId = newData.file.fileId;
              this.fileSize = newData.file.size; //上传大小
              this.dealFileds(newData)
            }
          });
        }
      },
      showQueRen() {
        this.isShowQueRen = true;
        this.isShowMatch = false;
        this.saveCustomFileds()
      },
      // 字段处理
      async dealFileds(newData) {
        this.excelForm = {};
        this.excelFields = newData.fields; //上传的字段名
        this.dbFields = newData.templateKey; //数据库字段名
        this.totalLength = newData.dataCount; //数据总数
        this.readLength = newData.dataSize; //每次读取数量
        let datalist = [];
        newData.dataList.forEach((x) => {
          //大小写不区分
          x = this.toLower(x);
          datalist.push(x);
        });
        this.excelList = datalist; //上传的数据

        let newArry = [];
        // 取上次保存的字段
        let newFiled = await this.getCustomFileds()
        if (newFiled && newFiled.length) {
          newFiled.forEach((i) => {
            // this.excelForm[i.zddm] = i.ppzdmc //匹配下拉框

            this.excelForm[i.zddm] = this.excelFields.some((s) => {
              return s.toLowerCase() == i.ppzdmc.toLowerCase();
            }) ? i.ppzdmc : ""

            this.dbFields.find((item) => { //匹配勾选状态

              if (item.mustImport) {
                newArry.push(item.dbColumnName);
              }
              let conditionB = item.dbColumnName === i.zddm
              if (this.excelForm[i.zddm] && conditionB) {

                item.checked = true
                return true
              }
            })


          });
        } else {
          this.dbFields.forEach((i) => {
            //自动匹配
            this.excelForm[i.dbColumnName] = this.excelFields.some((s) => {
              return s.toLowerCase() == i.dbColumnName.toLowerCase();
            }) ? i.dbColumnName : "";

            i.checked = this.excelFields.find(v => {
              return v.toLowerCase() == i.dbColumnName
            }) ? true : false
            //必选字段
            if (i.mustImport) {
              newArry.push(i.dbColumnName);
            }
          });
        }
        this.dbChooseFields = JSON.parse(JSON.stringify(this.dbFields));


        this.mustArry = newArry; //必传字段名

        this.$nextTick(() => {
          this.change(this.excelForm);
          this.isShowMatch = true;
          this.isShowDete = false;
          this.$refs.importFields.checkMatch();
        });
      },
      // 保存自定义匹配字段
      saveCustomFileds() {
        let customfileds = []
        for (let key in this.excelForm) {
          this.dbChooseFields.filter(k => {
            if (k.dbColumnName == key) {
              customfileds.push({
                zddm: key,
                ppzdmc: this.excelForm[key],
                zdmc: k.dbColumnMemo
              })
            }
          })

        }
        let drkid = this.getDrkId()
        saveCustomFileds(drkid, this.module, customfileds)

      },
      // 获取已匹配好的自定义字段
      getCustomFileds() {
        let drkid = this.getDrkId()
        let customArray = []
        return getCustomFileds(drkid, this.module).then(res => {
          if (res.status) {
            customArray = res.data
            return customArray
          }
          return [];
        })

      },
      //查询最近导入文件
      getLately() {
        let drkid = this.getDrkId()
        return getLatelyFile(drkid, this.module).then(res => {
          if (res.status) {
            this.historyFileId = res.data.fileId
            if (this.historyFileId) {
              this.hasHistory = true
            }
          }

        })

      },
      //最近上传文件
      latelyFile() {
        let paramsObj = {
          templateCode: this.module,
          fileId: this.historyFileId
        }
        if (this.historyFileId) {
          analysisLatelyFile(paramsObj).then(res => {
            if (res.status) {
              console.log(res);
              this.fileId = this.historyFileId
              let newData = res.data
              this.dealFileds(newData)
            }
          })
        }

      },
      //获取导入库的id
      getDrkId() {
        let drkid = ""
        let getName = this.hasKsbmbhList.find((k) => {
          return k.name == this.module;
        });
        if (this.module == "TSMDK" || this.module == "KSZYMDK" || getName) {
          drkid = this.ksbmbh;
        }
        if (this.module == "CJK" || this.module == "FHCJK") {
          drkid = this.cjkid;
        }
        if (this.module == "ZKZK") {
          drkid = this.zkzkid;
        }
        if (this.module == "ZSK") {
          drkid = this.zskid;
        }
        if (this.module == "XXTSMD") {
          drkid = this.tid;
        }
        if (drkid == "") {
          drkid = "noid"
        }
        return drkid
      },
      showMatch() {
        this.isShowMatch = !this.isShowMatch;
      },
      toLower(obj) {
        for (var k in obj) {
          obj[k.toLowerCase()] = obj[k];
          if (k.toLowerCase() != k) {
            delete obj[k];
          }
        }
        return obj;
      },
      // 确定导入
      sureImport() {
        let getName = this.hasKsbmbhList.find((k) => {
          return k.name == this.module;
        });
        let fileObj = {
          param: {
            drkid: this.getDrkId()
          },
          keyMap: {},

        };
        if (getName) {
          fileObj.param.ksbmbhParam = this.ksbmbh;
        }
        this.dbChooseFields.forEach((k) => {
          fileObj.keyMap[k.dbColumnName] = this.excelFields.filter((v) => {
            return this.excelForm[k.dbColumnName] == v.toLowerCase();
          })[0];
        });
        if (this.module == "TSMDK" || this.module == "KSZYMDK") {
          fileObj.param.ksbmbh = this.ksbmbh;
        }
        if (this.module == "CJK" || this.module == "FHCJK") {
          fileObj.param.cjkid = this.cjkid;
        }

        if (this.module == "ZKZK") {
          fileObj.param.zkzkid = this.zkzkid;
        }
        if (this.module == "ZSK") {
          fileObj.param.zskid = this.zskid;
        }
        if (this.module == "XXTSMD") {
          fileObj.param.tid = this.tid;
        }
        let sync = true;
        this.$confirm("是否确认导入数据?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            if (this.fileId) {
              importFileSure(this.fileId, this.module, fileObj).then((res) => {
                if (res.status) {
                  if (res.data) {
                    let statusData = res.data;
                    this.rollBackKey = statusData.rollBackKey;
                    this.importLength = statusData.successRows;
                    if (statusData.checkStatus) {
                      if (statusData.checkStatus != "success") {
                        this.$message({
                          type: "warning",
                          message: statusData.checkMsg ?
                            statusData.checkMsg : "请下载错误数据并重新上传!",
                        });
                        this.isShowDete = true;
                        return;
                      } else {
                        sync = false;
                        this.exportFinal();
                        this.hide();
                      }
                    }
                  }
                  if (this.importLength) {
                    this.$notify({
                      type: "success",
                      message: "已成功导入" + this.importLength + "条数据!",
                      duration: 3000,
                    });
                  } else {
                    this.$notify({
                      type: "success",
                      message: res.message,
                      duration: 3000,
                    });
                  }

                  this.hide();
                  if (this.success && sync) {
                    this.success();
                  }
                }
              });
            } else {
              this.$message({
                type: "warning",
                message: "暂无可导入数据",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消导入",
            });
          });
      },
      // 导入正式表
      exportFinal() {
        let formData = {};
        formData.templateCode = this.module;
        if (this.module == "CJK") {
          formData.cjkid = this.cjkid;
        } else if (this.module == "ZKZK") {
          formData.zkzkid = this.zkzkid;
        } else if (this.module == "ZSK") {
          formData.zskid = this.zskid;
        } else if (this.module == "XXTSMD") {
          formData.tid = this.tid;
        }
        formData.rollBackKey = this.rollBackKey;
        exportSubmit(formData).then((res) => {
          if (res.status) {
            if (this.success) {
              this.success();
            }
          }
        });
      },
      // 删除上传文件
      deleteFile() {
        this.$confirm("确定撤销刚才的导入?", "提示", {
            confirmButtonText: "确定",
            type: "warning",
          })
          .then(() => {
            deleteUpload(this.rollBackKey).then((res) => {
              if (res.status) {
                this.$message({
                  type: "success",
                  message: "数据撤销成功!",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
      // 下载错误数据
      downError() {
        let url;
        if (this.module == "CJK") {
          url =
            this.API_URL +
            "/yethan/register/downCheckList?templateCode=" +
            this.module +
            "&cjkid=" +
            this.cjkid +
            "&ytoken=" +
            this.getStore("accessToken");
        } else if (this.module == "ZKZK") {
          url =
            this.API_URL +
            "/yethan/register/downCheckList?templateCode=" +
            this.module +
            "&zkzkid=" +
            this.zkzkid +
            "&ytoken=" +
            this.getStore("accessToken");
        } else {
          url =
            this.API_URL +
            "/yethan/register/downloadBadLists?templateCode=" +
            this.module +
            "&ytoken=" +
            this.getStore("accessToken");
        }

        window.location.href = url;
      },
      // 下载模板
      downloadModule() {
        let url;
        if (this.module == "TSMDK" || this.module == "KSZYMDK") {
          url =
            this.API_URL +
            "/yethan/register/downloadTemplate/" +
            this.module +
            "?ksbmbh=" +
            this.ksbmbh +
            "&ytoken=" +
            this.getStore("accessToken");
        } else {
          url =
            this.API_URL +
            "/yethan/register/downloadTemplate/" +
            this.module +
            "?ytoken=" +
            this.getStore("accessToken");
        }

        window.location.href = url;
      },
      // 获取上传参数限制
      getLimit() {
        getUploadLimit({
          module: "Import",
        }).then((res) => {
          if (res.status) {
            // console.log(res)
            this.uploadLimit["ext"] =
              "." + res.data.extLimit.replaceAll(",", ",.");
            this.uploadLimit["size"] = res.data.sizeLimit;
          }
        });
      },
    },
    computed: {
      hasCheck() {
        let checkList = this.dbChooseFields.filter((k => {
          return k.checked
        }))
        return checkList.length || 0
      }
    },
    mounted() {
      this.getLimit();
    },
  };
</script>

<template>
  <div id="">
    <b-modal v-model="showHandle" centered :title="title" size="xl" title-class="font-18" hide-footer>

      <div class="mb-2">
        <div class="flexList">
          <div class="mr-2 text-dark">第一步：请先上传文件</div>
          <div class="blue-font" style="cursor: pointer; display: inline-block" @click="downloadModule">
            点击下载模板
          </div>
        </div>
        <div class="flexList mt-2">
          <el-upload class="upload-demo" action="" :auto-upload="false" :on-change="uploadFile"
            :accept="uploadLimit.ext">
            <el-button size="small" plain class="flexList h30" style="width: 110px"><i
                class="iconfont mr-1 icon-shangchuan"></i>上传文件</el-button>
          </el-upload>
          <el-tag type="danger" class="ml-2" style="height: 30px">
            支持上传{{ uploadLimit.ext }}文件，且不超过{{
              uploadLimit.size / 1024 / 1024
            }}M</el-tag>
          <el-button size="small" type="primary" class="flexList h30 ml-2" @click="latelyFile()" v-if="hasHistory">上传历史
          </el-button>
        </div>
      </div>
      <div>
        <div class="mb-2 flexList">
          <div class="text-dark">第二步：匹配需上传的字段</div>
          <el-button type="primary" size="mini" class="ml-2" v-show="isShowQueRen" @click="showMatch">
            <span v-if="isShowMatch">收起字段</span>
            <span v-else>展开字段</span>
          </el-button>
          <el-tag type="warning" class="ml-3" v-if="mustArry.length > 0">提示：<span
              class="text-danger font-size-16 mr-2 mt-2"> *</span>为必传字段必须选中，且值不得为空，否则将影响上传或其他业务功能，导入前请先确认。
          </el-tag>
          <span class="ml-2">已选择<span class="text-success">{{hasCheck}}</span>个字段</span>
        </div>
        <div style="max-height: 500px; overflow-y: auto" v-show="isShowMatch">
          <importFields :module="module" :excelFields="excelFields" :dbFields="dbFields" :ChooseFields="dbChooseFields"
            @dbChange="
              dbChooseFields = $event;
              $forceUpdate();
            " :mustArry="mustArry" v-model="excelForm" @change="change" class="mb-2" ref="importFields">
          </importFields>
        </div>

        <div class="mb-2 flexList">
          <div class="text-dark">第三步：校验需上传的数据</div>
          <el-button type="primary" size="mini" class="ml-2" @click="showQueRen">确认字段</el-button>
          <span v-show="isShowQueRen" class="ml-2">已读取<span class="text-danger">{{ readLength }}</span>条数据
            <span class="text-success"> 共{{ totalLength }}条 </span>
            <span class="text-info">共{{ (fileSize / 1024 / 1024).toFixed(2) }}M</span>
          </span>
        </div>

        <div style="max-height: 400px; overflow-y: auto">
          <importPreView ref="importPreView" :list="excelList" :bounds="bounds" :dbFields="dbChooseFields"
            v-show="isShowQueRen">
          </importPreView>
        </div>
      </div>
      <div class="text-center" v-if="!isShowDete">
        <el-button type="primary" size="medium" @click="sureImport">确认并导入</el-button>
      </div>
      <div class="text-center" v-else>
        <el-button type="primary" size="medium" @click="downError" class="mr-2">下载错误数据</el-button>
        <el-button type="info" size="medium" @click="deleteFile">删除已上传</el-button>
      </div>
    </b-modal>
  </div>
</template>

<template>
  <div v-if="familyList">
    <div class="mt-2 mb-2">
      <i class="iconfont icon-ze-user-circle-o mr-2"></i>家庭信息
    </div>
    <div class="msgContent flexList fs-xs">
      <table
        class="table light-table table-hover"
        v-if="familyList.length != 0"
      >
        <thead class="thead-light">
          <tr>
            <th>序号</th>
            <th>关系</th>
            <th>姓名</th>
            <th>性别</th>
            <th>民族</th>
            <th>出生年月</th>
            <th>职业</th>
            <th>工作学习单位</th>
            <th>职务</th>
            <th>联系电话</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in familyList" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ item.gxmc }}</td>
            <td>{{ item.cyxm }}</td>
            <td>{{ item.xb }}</td>
            <td>{{ item.mzmc }}</td>
            <td>{{ item.csrq }}</td>
            <td>{{ item.cyemc }}</td>
            <td>{{ item.gzxxdw }}</td>
            <td>{{ item.zw }}</td>
            <td>{{ item.lxdh }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {getUserFamily} from "@/api/userInfo_user.js";
export default {
  props: {
    sfzjh: String,
  },
  data() {
    return {familyList: []};
  },
  mounted() {
    getUserFamily(this.sfzjh).then((res) => {
      if (res.status) {
        this.familyList = res.data;
      }
    });
  },
};
</script>

<style></style>

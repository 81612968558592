<!-- 证书导入弹窗-->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-upload-list {
    display: inline-flex;
  }

  .modal-body {
    overflow-y: auto;
  }
</style>
<script>
  import importFields from "@/components/export/importFields";
  import importPreView from "@/components/export/importPreView";
  import AddCertKu from "@/views/pages/admin/certificate/addCertku.vue"
  import {
    importFileCustom,
    importFileSure,
    deleteUpload,
    exportSubmit,
    checkFile,
    getUploadLimit
  } from "@/api/common/public.js"
  // import {
  //   checkCert
  // } from "@/api/zhengshu/record.js"
  import {
    getZsk,
    addZsk
  } from "@/api/zhengshu/manage.js"
  /**
   * Dashboard component
   */
  export default {
    name: "importModal",
    components: {
      importFields,
      importPreView,
      AddCertKu
    },
    model: {
      event: "input",
      data: "form",
    },
    data() {
      return {
        showHandle: false,
        form: {},
        excelForm: {},
        excelFields: [],
        bounds: {},
        sureList: [],
        excelList: [],
        dbFields: [],
        isShowQueRen: false,
        isShowMatch: true,
        hasSelect: false,
        isShowNext: false, //是否继续下一步
        isShowDete: false, //显示撤销上传和下载错误数据
        zskid: "",
        zskList: [],
        fileList: [],
        uploadLimit: { //导入限制
          size: 10485760,
          ext: ".dbf"
        },
        rollBackKey: "", //撤销key
        fileId: "",
        type: "",
        title: "证书信息导入",
        zskmc: "",
        module: "",
        ksbmbh: "",
        fileSize: "",
        dbChooseFields: [],
        mustArry: [],
        totalLength: "", //总条数
        readLength: "", //已读取
        importLength: "", //成功导入
        hasKsbmbhList: [{
            name: "CJDAK",
          },
          {
            name: "WJK",
          },
          {
            name: "HMDK",
          },
          {
            name: "CJk"
          }
        ],
        success: null,
      };
    },
    methods: {
      show(conf) {
        if (conf.success) {
          this.success = conf.success
        }
        if (conf.zskid) {
          this.zskid = conf.zskid
          this.zskmc = conf.zskmc
        }
        this.form = {}
        this.isShowNext = false;
        this.type = conf.type
        this.module = conf.module
        this.ksbmbh = conf.ksbmbh
        this.showHandle = true
        this.fileId = ""
        this.excelFields = []
        this.excelList = []
        this.dbFields = []
        this.mustArry = []
        this.dbChooseFields = []
        this.excelForm = {}

      },
      hide() {
        this.showHandle = false;
      },
      nextStep() {
        if (this.zskid) {
          this.isShowNext = true;
        } else {
          this.$message({
            type: 'warning',
            message: '请先选择证书库!',
          });
        }
      },
      backTo() {
        this.isShowNext = false;
      },
      change(e) {
        this.hasSelect = true
        this.bounds = e;
        this.$refs.importPreView.refresh(e)
        this.$forceUpdate();
        this.$emit("change", e);
      },

      uploadFile(file, fileList) {
        var _this = this;
        if (checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file.raw)) {
          let upParams = {
            module: "Import",
            templateCode: this.module
          }
          if (this.module == "TSMDK") {
            upParams.ksbmbh = this.ksbmbh
          }

          importFileCustom(file.raw, upParams).then(res => {

            if (res.status) {
              console.log(res)

              if (fileList.length > 1) {
                fileList.splice(0, 1);
              }
              let newData = res.data
              this.excelForm = {}
              this.fileId = newData.file.fileId
              this.excelFields = newData.fields
              this.dbFields = newData.templateKey
              this.totalLength = newData.dataCount
              this.readLength = newData.dataSize
              this.fileSize = newData.file.size //上传大小
              let datalist = [];
              newData.dataList.forEach(x => { //大小写不区分
                x = this.toLower(x)
                datalist.push(x)

              })
              this.excelList = datalist

              let newArry = []
              this.dbFields.forEach(i => { //自动匹配
                this.excelForm[i.dbColumnName] = this.excelFields.some(s => {
                  return s.toLowerCase() == i.dbColumnName.toLowerCase()
                }) ? i.dbColumnName : ''
                if (i.mustImport) {
                  newArry.push(i.dbColumnName)
                }
              })
              this.mustArry = newArry

              this.dbChooseFields = JSON.parse(JSON.stringify(this.dbFields))
              this.$nextTick(() => {
                this.isShowMatch = true
                this.isShowDete = false
              })
            }
          });
        }

      },
      showQueRen() {
        this.change(this.excelForm);
        this.isShowQueRen = true;
        this.isShowMatch = false
      },
      showMatch() {
        this.isShowMatch = !this.isShowMatch
      },
      // 大小写也能匹配
      toLower(obj) {
        for (var k in obj) {
          obj[k.toLowerCase()] = obj[k]
          if (k.toLowerCase() != k) {
            delete(obj[k])
          }
        }
        return obj
      },
      sureImport() {
        let getName = this.hasKsbmbhList.find(k => {
          return k.name == this.module
        })
        let fileObj = {
          param: {},
          keyMap: {}
        }
        if (getName) {
          fileObj.param.ksbmbhParam = this.ksbmbh
        }
        fileObj.param.zskid = this.zskid
        fileObj.param.zskmc = this.zskmc
        this.dbChooseFields.forEach(k => {
          fileObj.keyMap[k.dbColumnName] = this.excelFields.filter(v => {
            return this.excelForm[k.dbColumnName] == v.toLowerCase()
          })[0]
        })
        importFileSure(this.fileId, this.module, fileObj).then(res => {
          if (res.status) {
            console.log(res)
            if (res.data) {
              let statusData = res.data
              this.rollBackKey = statusData.rollBackKey
              this.importLength = statusData.successRows
              if (statusData.checkStatus) {
                if (statusData.checkStatus != "success") {
                  this.$message({
                    type: 'warning',
                    message: statusData.checkMsg ? statusData.checkMsg : '请下载错误数据并重新上传!'
                  });
                  this.isShowDete = true
                  return;
                } else {
                  this.hide()
                }
              }
            }
            if (this.importLength) {
              this.$notify({
                type: 'success',
                message: "已成功导入" + this.importLength + "条数据!",
                duration: 3000,
              })
            } else {
              this.$notify({
                type: 'success',
                message: res.message,
                duration: 3000,
              })
            }
            this.hide()
            if (this.success) {
              this.success()
            }
          }
        })
      },
      // 导入正式表
      exportFinal() {
        let formData = {}
        formData.templateCode = this.module
        formData.zskid = this.zskid
        exportSubmit(formData)
      },
      // 删除上传文件
      deleteFile() {
        this.$confirm('确定撤销刚才的导入?', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        }).then(() => {
          deleteUpload(this.rollBackKey).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据撤销成功!'
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },
      // 下载错误数据
      downError() {
        let url
        if (this.module == "ZSK") {
          url = this.API_URL + "/yethan/register/downCheckList?templateCode=" + this.module + "&zskid=" + this.zskid +
            "&ytoken=" + this
            .getStore(
              'accessToken')
        } else {
          url = this.API_URL + "/yethan/register/downloadBadLists?templateCode=" + this.module + "&ytoken=" + this
            .getStore(
              'accessToken')
        }

        window.location.href = url
      },
      // 下载模板
      downloadModule() {
        let url = this.API_URL + "/yethan/register/downloadTemplate/" + this.module + "?ytoken=" + this.getStore(
          'accessToken')
        window.location.href = url

      },
      // 证书库列表
      getZskList() {
        getZsk().then(res => {
          if (res.status) {
            this.zskList = res.data
          }
        })
      },
      getZskmc(val) {
        let obj = this.zskList.find(k => {
          return k.zskid === val
        })
        this.zskmc = obj.zskmc
      },
      showAddZsk() {
        this.$refs.AddCertKu.show()
      },
      // 添加证书
      addZskList(e) {
        let formDate = JSON.parse(JSON.stringify(e))
        addZsk(formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message,
            })
            this.$refs.AddCertKu.hide()
          }

        })
      },
      // 获取上传参数限制
      getLimit() {
        getUploadLimit({
          module: "Import"
        }).then(res => {
          if (res.status) {
            this.uploadLimit["ext"] = "." + res.data.extLimit.replaceAll(",", ",.");
            this.uploadLimit["size"] = res.data.sizeLimit;
          }
        });
      }
    },
    mounted() {
      this.getZskList()
      this.getLimit()
    },
  };
</script>

<template>
  <div>
    <b-modal v-model="showHandle" centered :title="this.title" size="xl" title-class="font-18" hide-footer>
      <div>
        <div class="grey-time mb-2">可以批量导入证书，在导入证书前必须先创建证书库（分类）。</div>
        <div v-if="!isShowNext">
          <div class="mb-2">
            <div class="mb-2 text-dark ">第一步，请选择证书库（分类）</div>
            <div class="flexList">
              <select name="" class="form-control form-select w-30 mr-2" v-model="zskid" @change="getZskmc(zskid)">
                <option value="">请选择证书库（分类）</option>
                <option :value="item.zskid" v-for="(item,i) in zskList" :key="i">{{item.zskmc}}</option>
              </select>
              <el-button type="primary" plain size="medium" class="flexList h30" icon="el-icon-circle-plus-outline"
                @click="showAddZsk">添加证书库</el-button>
            </div>

          </div>
          <div class="mb-2 ">
            <div class="text-dark mb-2">第二步，请导入证书信息</div>
            <div>1、请必须使用dbf进行导入，点击此处
              <span class="blue-font" style="cursor: pointer;" @click="downloadModule">下载证书导入dbf模板；</span>
            </div>
            <div>2、请不要重复导入数据，导入前请做好数据校验。</div>
            <div class="mt-2 flexList">
              <el-upload class="upload-demo " action="" :auto-upload="false" :on-change="uploadFile"
                :accept="uploadLimit.ext" :limit="1">
                <el-button size="small" plain class="flexList h30" style="width: 110px"><i
                    class="iconfont mr-1 icon-shangchuan"></i>上传文件</el-button>
              </el-upload>
              <el-tag type="danger" class="ml-2 " style="height: 30px;">
                支持上传{{ uploadLimit.ext }}文件，且不超过{{ uploadLimit.size/1024/1024 }}M</el-tag>
            </div>

          </div>
          <div class="text-center">
            <el-button type="primary" size="medium" @click="nextStep">下一步，数据匹配</el-button>
            <el-button type="info" size="medium" @click="hide">取消</el-button>
          </div>
        </div>
        <div v-else>
          <div class="flexList mb-2">
            <el-button type="primary" size="mini" class="mb-2" @click="backTo">返回上一步</el-button>
            <el-button type="primary" size="mini" class="mb-2" @click="showMatch" v-show="isShowQueRen">
              <span v-if="isShowMatch">收起字段</span>
              <span v-else>展开字段</span>
            </el-button>
            <el-tag type="warning" class="ml-3" v-if="mustArry.length>0">提示：<span
                class="text-danger font-size-16 mr-2 mt-2">
                *</span>为必传字段必须选中，且值不得为空，否则将影响上传或其他业务功能，导入前请先确认。
            </el-tag>
          </div>
          <div style="max-height:500px ;overflow-y: auto;" v-if="isShowMatch">
            <importFields :module="module" :excelFields="excelFields" :dbFields="dbFields"
              :ChooseFields="dbChooseFields" @dbChange="dbChooseFields = $event;$forceUpdate()" :mustArry="mustArry"
              v-model="excelForm" @change="change" class="mb-2">
            </importFields>

          </div>

          <div class="mb-2 text-dark">第三步：校验需上传的数据</div>
          <el-button type="primary" size="mini" class="mt-2 mb-2" @click="showQueRen">确认字段</el-button>
          <span v-show="isShowQueRen" class="ml-2">已读取<span class="text-danger">{{readLength}}</span>条数据
            <span class="text-success">
              共{{totalLength}}条
            </span>
            <span class="text-info">共{{(fileSize/1024/1024).toFixed(2)}}M</span>
          </span>
          <div style="max-height:400px ;overflow-y: auto;">
            <importPreView ref="importPreView" :list="excelList" :bounds="bounds" :dbFields="dbChooseFields"
              v-show="isShowQueRen">
            </importPreView>
          </div>
          <div>
            <div class="text-center" v-if="!isShowDete">
              <el-button type="primary" size="medium" @click="sureImport">确认并导入</el-button>
            </div>
            <div class="text-center" v-else>
              <el-button type="primary" size="medium" @click="downError" class="mr-2">下载错误数据</el-button>
              <el-button type="info" size="medium" @click="deleteFile">删除已上传</el-button>
            </div>
          </div>

        </div>


      </div>

    </b-modal>
    <AddCertKu @submit="addZskList" title="添加证书库" ref="AddCertKu"></AddCertKu>

  </div>
</template>

<template>
  <div class="msgContent flexList fs-xs">
    <div class="flexList w-100 msgLabel ">
      <div class="filedName col-sm-1 text-left">全选
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
          style="display: inline;">
        </el-checkbox>
      </div>
      <div class="filedName col-sm-3 text-center">字段说明</div>
      <div class="filedName col-sm-2 text-center">目标字段</div>
      <div class="filedName col-sm-4 text-left">源字段</div>
      <div class="filedName col-sm-1 text-left">字段类型</div>
      <div class="filedName col-sm-1 text-left">字段长度</div>

    </div>

    <div class="flexList w-100 msgLabel" v-for="(dbField, i) in dbFields" :key="i">
      <div class="msgVal col-sm-1  text-center">
        <el-checkbox-group v-model="checkedDbFields" @change="dbChange">
          <el-checkbox :label="dbField">
            {{empty}}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="msgVal col-sm-3  text-center">
        {{dbField.dbColumnMemo}}
      </div>
      <div class="msgVal col-sm-2  text-center">
        {{dbField.dbColumnName}}
        <span class="text-danger" v-if="mustArry.indexOf(dbField.dbColumnName)>-1">*</span>
      </div>

      <div class="msgVal selectShow  col-sm-4 flexList ">
        <yzSelect :dict="excelList" v-model="form" :bound="[dbField.dbColumnName]"
          @change="change($event,dbField.dbColumnName,i)" class="w-50 mr-1">
        </yzSelect>
        {{form[dbField.dbColumnName] == dbField.dbColumnName?'':'*该字段没有可匹配的字段'}}
      </div>
      <div class="msgVal selectShow col-sm-1 flexList ">
        {{dbField.dbColumnType}}
      </div>
      <div class="msgVal selectShow col-sm-1 flexList ">
        {{dbField.columnLength}}
      </div>
    </div>
  </div>
</template>

<script>
  import yzSelect from "../form/yzSelect";
  export default {
    name: "exportFields",
    props: {
      excelFields: Array,
      module: String,
      form: Object,
      dbFields: Array,
      ChooseFields: Array,
      mustArry: Array,
    },
    model: {
      prop: "form",
      event: "input"
    },
    data() {
      return {
        excelList: [],
        isMatch: true,
        checkAll: false,
        isIndeterminate: false,
        checkedDbFields: [],
        empty: "",
        showText: false
      }
    },

    methods: {
      change(e, memo) {
        this.$emit("input", this.form)
        this.$emit("change", this.form)
        let data = {}
        data = this.dbFields.filter(v => {
          return memo == v.dbColumnName
        })[0]
        if (e.value != "") {
          if (!this.checkedDbFields.filter(v => {
              return v.dbColumnName == data.dbColumnName
            })[0]) {
            data.checked = true;
            this.checkedDbFields.push(data)
          }
        } else {
          let index = this.checkedDbFields.findIndex(item => item.dbColumnName == data.dbColumnName)
          if (index > -1) {
            this.checkedDbFields.splice(index, 1)
          }
        }
        this.dbChange()


      },
      dbChange() {

        this.$emit("dbChange", this.checkedDbFields)
      },
      handleCheckAllChange(e) {
        this.isIndeterminate = false
        this.checkedDbFields = e ? this.dbFields : []
        if (this.checkedDbFields.length == 0) {
          this.checkAll = false
        } else {
          this.checkAll = true
        }
        this.dbChange()
      },
      checkMatch() {
        this.dbFields.forEach(i => {
          if (i.checked) {
            this.checkedDbFields.push(i)
          }
        })
        this.dbChange()
      }

    },
    created() {

    },
    mounted() {},
    components: {
      yzSelect,
    },
    watch: {
      dbFields: {
        immediate: true,
        handler(e) {
          this.lowDbFields = e
          this.checkMatch()
        }

      },
      excelFields: {
        deep: true,
        immediate: true,
        handler(list) {

          this.excelList = [{
            name: "请选择",
            value: ""
          }]
          list.forEach(item => {
            this.excelList.push({
              name: item,
              value: item.toLowerCase()
            })
          })
        }
      },
      mustArry: {
        deep: true,
        handler(arr) {
          this.mustArry = arr

        }
      },
      checkedDbFields: {
        immediate: true,
        deep: true,
        handler(e) {
          this.checkAll = this.dbFields.length > 0 && (e.length == this.dbFields.length)
          this.isIndeterminate = e.length > 0 && e.length < this.dbFields.length;

        }

      }
    }
  }
</script>

<style scoped>
  .filedName {
    border-top: 1px solid #DFDFDF;
    border-left: 1px solid #DFDFDF;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #F3F5FB;
    height: 100%;
  }

  /deep/.form-select {
    padding-top: 4px;
  }
</style>

import { getRequest,postRequest,putRequest,patchRequest,deleteRequest,downloadRequest} from "@/libs/axios";
// 考试类别

// 考试大类
export function listPage(param) {
    return getRequest( '/yethan/register/mainType/listPage', param);
}
// 更新启用状态
export function updateMainTypeQyzt(data={}) {
    return patchRequest( '/yethan/register/mainType/updateMainTypeQyzt', data);
}
// 删除考试大类
export function deletMainType(sid) {
    return deleteRequest('/yethan/register/mainType/'+sid);
}
// 添加考试类别
export function addMainType(data={}) {
    return postRequest('/yethan/register/mainType',data);
}
// 编辑考试类别
export function editMainType(data={}) {
    return putRequest('/yethan/register/mainType',data);
}
// 大类类别筛选
export function easyList(data={}) {
    return getRequest('/yethan/register/mainType/easyList',data);
}
//大类导出excel
export function exportMainExcel(data={}) {
    return downloadRequest('/yethan/register/mainType/exportExcel',data);
}


// 考试二级分类列表
export function secondListPage(pageData) {

    return getRequest( '/yethan/register/secondType/listPage', pageData);
} 
// 更新二级分类启用状态
export function updateSecondTypeQyzt(data={}) {
    return patchRequest( '/yethan/register/secondType/updateSecondTypeQyzt', data);
}
// 删除考试二级分类
export function deletSecondType(sid) {
    return deleteRequest('/yethan/register/secondType/'+sid);
}
// 添加考试二级类别
export function addSecondType(data={}) {
    return postRequest('/yethan/register/secondType',data);
}
// 编辑考试二级类别
export function editSecondType(data={}) {
    return putRequest('/yethan/register/secondType',data);
}
// 二级类别筛选
export function SecondEasyList(data={}) {
    return getRequest('/yethan/register/secondType/easyList',data);
}
//二级类别导出excel
export function exportSecondExcel(data={}) {
    return downloadRequest('/yethan/register/secondType/exportExcel',data);
}
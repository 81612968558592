// 获取通知
import { getRequest, deleteRequest,patchRequest } from "@/libs/axios";

// 获取通知列表
export const getWebNoticePage = params => {
    return getRequest("/yethan/WebNotice/self/listPage", params);
};
// 获取通知列表
export const getWebNoticeTop = params => {
    return getRequest("/yethan/WebNotice/self/listPage/top", params, false);
};

// 删除通知列表
export const deleteWebNotice = nid => {
    return deleteRequest(`/yethan/WebNotice/self/${nid}`);
};


// 获取详情
export const getWebNoticeDetails = nid => {
    return getRequest(`/yethan/WebNotice/${nid}`);
};

// 更新状态
export const updateWebNoticeSfyd = (noticeId) => {
    return patchRequest(`/yethan/WebNotice/${noticeId}/sfyd`)
}
